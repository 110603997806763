import React, { useState, useEffect } from "react";
import DefaultLayout from "../../layouts/DefaultLayout";
import { useNavigate } from "react-router-dom"
import { postData,getData } from "../../services/HttpServices";
import { PrimaryButton } from "../../components/Button";
import { AlertError, AlertSuccess } from "../../components/Alerts";

import DateRange from "../../components/DateRange";
import { setPageTitle,setBreadcrumbs } from '../../store/resourceSlice';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import CreateFormPopup from "./createFormPopup";
const CreateProductionPlan = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [title, setTitle] = useState();
    const { handleSubmit } = useForm();
    const [productRows, setProductRows] = useState([]);
    const [productRow, setProductRow] = useState([]);
    const [productIndex, setProductIndex] = useState();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [show, setShow] = useState(false);
    const [productData, setProductData] = useState();
    const onSubmit = (data) => {
        if (!title) {
            AlertError("Please enter title");
            return;
        }
        if (!startDate && !endDate) {
            AlertError("Please select a date range");
            return;
        }
        if (productRows.length === 0) {
            AlertError("Please add a product.");
            return;
        }
        let errors = productRows.filter((res) => {
            return res.product === null || res.pallets === 0 || res.bottles === 0 ;
        });
        if (errors.length > 0) {
            AlertError("The product section required attention. All fields are required.");
            return;
        }
        let newStartDate = startDate.toLocaleDateString("en-UK");
        let newEndDate = endDate.toLocaleDateString("en-UK");
        data.title=title
        data.startDate=newStartDate
        data.endDate=newEndDate
        data.products = productRows
        postData('/productionplans/create', data).then((response) => {
            let resposnseData = response.data
            if (resposnseData.success === false) {
                AlertError(resposnseData.message);
                return
            }
            AlertSuccess(resposnseData.message)
            navigate("/productionplans");
        }).catch(e => {
            AlertError(e);
        })
    };

    const addProduct = (res) => {
      if(productIndex){
        let newAdded =[...productRows];
         newAdded[productIndex-1] = res;
         setProductRows(newAdded);
         setProductIndex(null)
      }else{
        let newAdded = [...productRows, res];
        setProductRows(newAdded);
      }
        

     };
    const removeProduct = (index) => {
        let newRows = productRows.filter((arrow, key) => key !== index-1);
        setProductRows(newRows);
    };

    const editProduct = (index) => {
        setProductRow(productRows[index-1])
        setProductIndex(index);
        setShow(true);
    }
    const dateRangeFn = (res) =>{
        const [start, end] = res;
        setStartDate(start);
        setEndDate(end);
    }
    const hideForm = () => {
      setShow(false);
    };
    const showForm = () => {
          setShow(true);
    };
    useEffect(() => {
        
        dispatch(setPageTitle('Create Production Plan'))
        dispatch(setBreadcrumbs([
          {
              label: 'Dashboard',
              link: '/dashboard',
              active: false, // true,false
              state: '/productionplans/create',
          },{
              label: 'Production Plans',
              link: '/productionplans',
              active: false, // true,false
              state: '/productionplans/create',
          },{
            label: 'Create Production Plan',
            link: '/productionplans/create',
            active: true, // true,false
            state: '/productionplans/create',
        }
        ]))
        getData("/products/sale/filter").then((response) => {
          if (response.status === 200) {
              let responseData = response.data;
              setProductData(responseData.data);
          }
      });
    }, [dispatch]);
    return (
        <DefaultLayout >
      <section className="section">
        <div className="row">
          <div className="card">
                     <div className="card-body">
                    <div className="container py-5">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                    <div className="col-lg-12 col-xl-12">
                    <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                    <div className="col-lg-4 col-xl-4 mb-3">
                          <label htmlFor="supplier" className="col-12 col-form-label">Title<span className="text-danger">*</span></label>
                            <div className="col-12">
                            <input
                                type="text"
                                className="form-control"
                                id="title"
                                defaultValue={title}
                                onInput={(res) => {
                                    setTitle(res.target.value);
                                  }}
                            />
                            </div>
                                   
                         </div>
                         <div className="col-lg-4 col-xl-4 mb-3">
                          <label htmlFor="deliverydate" className="col-12 col-form-label">Date<span className="text-danger">*</span></label>
                            <div className="col-12">
                            <DateRange startDate={startDate} endDate={endDate} onDateRangeChange={(res) => dateRangeFn(res)} placeholderText={'Date Range'}  />
                              </div> 
                         </div>
              <hr />
              
              <div className="row g-3 mb-3">
                <div className="col-12 col-md-12">
                  <div className="table-responsive">
                  <table className="table align-middle">
                    <thead>
                    <tr>
                      <th>Product</th>
                      <th>DRS</th>
                      <th>Spec File Page no</th>
                      <th>Spec Ref</th>
                      <th>Pallets</th>
                      <th>Bottles</th>
                      <th>Litre Per Pallets</th>
                      <th>Syrup</th>
                      <th>Caps</th>
                      <th>Actual Bottle</th>
                      <th>Actual Syrup</th>
                      <th>Barcode & Pallet Sheet Colour</th>
                      <th>ADDITIONAL PALLET SHEETS- 4 PER PALLET</th>
                      <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {productRows && productRows.length > 0 && productRows.map((product, index)=>(
                      <tr key={index+1}>
                      <td>{product.product.label || '-'}</td>
                      <td>{product.drs || '-'}</td>
                      <td>{product.fileno || '-'}</td>
                      <td>{product.Ref || '-'}</td>
                      <td>{product.pallets || '-'}</td>
                      <td>{product.bottles || '-'}</td>
                      <td>{product.litresperpallets || '-'}</td>
                      <td>{product.syrup || '-'}</td>
                      <td>{product.caps || '-'}</td>
                      <td>{product.actual_bottle || '-'}</td>
                      <td>{product.actual_syrup || '-'}</td>
                      <td>{product.barcode_pallet_sheet_colour || '-'}</td>
                      <td>{product.additonal_pallet_sheets || '-'}</td>
                      <td><PrimaryButton
                    title="Edit Product"
                    clickfun={()=>editProduct(index+1)}
                    iclass="pencil"
                    buttonClass='info btn-sm'
                  /><PrimaryButton
                    title="Remove Product"
                    clickfun={()=>removeProduct(index+1)}
                    iclass="x"
                    buttonClass='danger btn-sm'
                  /></td>
                    </tr>
                    ))}
                    </tbody>
                  </table>
                  </div>
                </div>
              </div>
              <div className="row g-3 mb-3">
              <div className="col-6 col-md-3">
              <PrimaryButton
                    title="Add Product"
                    clickfun={showForm}
                    iclass="plus"
                    buttonClass='primary'
                  />
                  </div>
                  </div>
              <div className="row g-3 mb-3">
                <div className="col-12 col-md-12">
              <div className="text-center">
                <button
                  type="submit"
                
                  className="btn btn-primary sub-btn"
                >
                  Submit
                </button>
              </div>
              </div>
              </div>
            </div>
            </form>
          </div>
        </div>
                 </div>
            </div>
        </div>
        </div>
      </section>
      <CreateFormPopup
                show={show}
                hideFn={hideForm}
                productRows={productRows}
                addProduct={addProduct}
                productRow={productRow}
                productData={productData}
           />
    </DefaultLayout>
    );
};
export default CreateProductionPlan;