import React, { useEffect,useState } from "react";
import DefaultLayout from "../../layouts/DefaultLayout";
import { setPageTitle,setBreadcrumbs } from "../../store/resourceSlice";
import { useDispatch } from "react-redux";
//import { Link } from "react-router-dom";
import { getData } from "../../services/HttpServices";
import { BarChart } from '@mui/x-charts/BarChart';
import { PieChart } from '@mui/x-charts/PieChart';
import { Link } from "react-router-dom";
import DateRange from "../../components/DateRange";
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { Badge } from "react-bootstrap";
//import DatePicker from "react-datepicker";
const Dashboard = () => {
    const dispatch = useDispatch();
    const [counterData, setCounterData] = useState({
        'sales_revenue_euro':0,
        'sales_revenue_pound':0,
        'total_po':0,
        'total_so':0,
    });

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [pOSupplierData, setPOSupplierData] = useState([]);
    const [pOSupplierDataKeys, setPOSupplierDataKeys] = useState([]);
    const [pOSupplierDataCounts, setPOSupplierDataCounts] = useState(0);
    const [upcomingPODeliveries, setUpcomingPODeliveries] = useState([]);
    const [limitInventories, setLimitInventories] = useState([]);
    const [upcomingSalesDeliveries, setUpcomingSalesDeliveries] = useState([]);
    const [mostdeliveriesByPartner, setMostdeliveriesByPartner] = useState([]);
    const [limitSaleInventories, setSaleLimitInventories] = useState([]);
    const [DelayedPO, setDelayedPO] = useState([]);
    const [DelayedSO, setDelayedSO] = useState([]);
    const getDashboardData = () =>{
        let url = '/dashboard'
        getData(url).then((response)=>{
            if(response.status === 200){
                let responseData = response.data
                setCounterData(responseData.data.counters)
                setPOSupplierData(responseData.data.poIssuedAsPerSuppliers)
                setPOSupplierDataKeys(responseData.data.poIssuedAsPerSuppliersDataKeys)
                setPOSupplierDataCounts(responseData.data.poIssuedAsPerSuppliersCounts)
                setUpcomingPODeliveries(responseData.data.upcomingPODeliveries)
                setLimitInventories(responseData.data.limitInventories)
                setUpcomingSalesDeliveries(responseData.data.upcomingSalesDeliveries)
            }
        })
    }
    const getMostdeliveriesByPartner = (startDate='',endDate='') =>{
        let url = '/dashboard/mostdeliveries?startDate='+startDate+'&endDate='+endDate
        getData(url).then((response)=>{
            if(response.status === 200){
                let responseData = response.data
                setMostdeliveriesByPartner(responseData.data)
            }
        })
    }
    const graphdata = (startDate='',endDate='') =>{
        let url = '/dashboard/graphdata'
        getData(url).then((response)=>{
            if(response.status === 200){
                let responseData = response.data
                setSaleLimitInventories(responseData.data.limit_stock_so)
                setDelayedPO(responseData.data.delayed_po)
                setDelayedSO(responseData.data.delayed_so)
            }
        })
    }
    
    const dateRangeFn = (res) =>{
        const [start, end] = res;
        setStartDate(start);
        setEndDate(end);
        if(start && end){
            getMostdeliveriesByPartner(start.toLocaleDateString("en-UK"),end.toLocaleDateString("en-UK"))
        }else{
            getMostdeliveriesByPartner()
        }
        
    }
    useEffect(() => {
        getDashboardData()
        getMostdeliveriesByPartner()
        graphdata()
        dispatch(setBreadcrumbs([
            {
                label: 'Dashboard',
                link: '/dashboard',
                active: true, // true,false
                state: 'dashboard',
            }
        ]))
        dispatch(setPageTitle("Dashboard"));
        // eslint-disable-next-line
    }, [dispatch]);
      
    const chartSetting = {
        yAxis: [
          {
            label: 'Purchase Orders',
          },
        ],
        width: 500,
        height: 260,
        sx: {
          [`.${axisClasses.left} .${axisClasses.label}`]: {
            transform: 'translate(-20px, 0)',
          },
        },
        legend:{ hidden: true }
      };
      
    return (
        <DefaultLayout>
       <section className="section total-info">
                <div className="row d-flex align-items-center justify-content-center">
                    <div className="col-xl-3 col-md-3 col-sm-6">
                        <div className="card">
                            <div className="card-body p-3 d-flex align-items-center">
                                <div className="total-icons">
                                    <img src="/images/sale-amount.png" alt="" width={73} height={73} />
                                </div>
                                <div className="card-info-title">
                                    <h6>Total Sales(€)</h6>
                                    <h5>{counterData.sales_revenue_euro}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-md-3 col-sm-6">
                        <div className="card">
                            <div className="card-body p-3 d-flex align-items-center">
                                <div className="total-icons">
                                <img src="/images/pound.png" alt="" width={73} height={73} />
                                </div>
                                <div className="card-info-title">
                                    <h6>Total Sales(£)</h6>
                                    <h5>{counterData.sales_revenue_pound}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-xl-2 col-md-2">
                        <div className="card">
                            <div className="card-body p-3 d-flex align-items-center">
                                <div className="total-icons">
                                    <img src="/images/total-profit.png" alt=""/>
                                </div>
                                <div className="card-info-title">
                                    <h6>Total Profit</h6>
                                    <h5>€185K</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-2 col-md-2">
                        <div className="card">
                            <div className="card-body p-3 d-flex align-items-center">
                                <div className="total-icons">
                                    <img src="/images/total-sale.png" alt="" />
                                </div>
                                <div className="card-info-title">
                                    <h6>Inventory Level</h6>
                                    <h5>70%</h5>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="col-xl-3 col-md-3 col-sm-6">
                        <div className="card">
                            <div className="card-body p-3 d-flex align-items-center">
                                <div className="total-icons">
                                    <img src="/images/purchase-orders.png" alt="" width={73} height={73} />
                                </div>
                                <div className="card-info-title">
                                    <h6>Total PO</h6>
                                    <h5>{counterData.total_po}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-md-3 col-sm-6">
                        <div className="card">
                            <div className="card-body p-3 d-flex align-items-center">
                                <div className="total-icons">
                                    <img src="/images/sale-orders.png"  alt="" width={73} height={73}/>
                                </div>
                                <div className="card-info-title">
                                    <h6>Total SO</h6>
                                    <h5>{counterData.total_so}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-6 col-md-6">
                        <div className="card " style={{minHeight:"370px"}}>
                        <div className="card-title mx-3">
                                    <h6>Purchase Orders Issued to Suppliers ({pOSupplierDataCounts})</h6>
                                </div>
                            <div className="card-body p-3 align-items-center">
                            <BarChart
                            dataset={pOSupplierData}
                            xAxis={[{ scaleType: 'band', dataKey: 'date' }]}
                            series={pOSupplierDataKeys}
                            {...chartSetting}
                            />
                           </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-md-6">
                        <div className="card" style={{minHeight:"370px"}}>
                        <div className="card-title mx-3">
                                    <span className="float-start text-dark">
                                    <h6>Upcoming Purchase Order Deliveries</h6> 
                                    </span>
                                    <span className="float-end"><Link to={"/purchaseorders"}>View All</Link></span>  
                                </div>
                            <div className="card-body p-3 align-items-center" >
                            <table className="table">
                                <thead>
                                    <tr>
                                    <th scope="col">Supplier Name</th>
                                    <th scope="col">Total Products</th>
                                    <th scope="col">Total Quantities</th>
                                    <th scope="col">Delivery Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {upcomingPODeliveries && upcomingPODeliveries.length > 0 ? upcomingPODeliveries.map((upcomingPODeliveries) => (
                                            <tr key={upcomingPODeliveries.id}>
                                                <td>{upcomingPODeliveries.supplier_name}</td>
                                                <td>{upcomingPODeliveries.totalproducts}</td>
                                                <td>{upcomingPODeliveries.totalquantity}</td>
                                                <td>{upcomingPODeliveries.delivery_date}</td>
                                            </tr>
                                    )): <tr key={1}><td colSpan={4} className=" text-center"> No Records Found</td></tr>}
                                </tbody>
                                </table>
                           </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-6 col-md-6">
                    <div className="card" style={{minHeight:"370px"}}>
                        <div className="card-title mx-3">
                                    <span className="float-start text-dark">
                                    <h6>Limited Inventory (PO)</h6> 
                                    </span>
                                    <span className="float-end"><Link to={"/purchaseproducts?orderby=quantity-asc"}>View All</Link></span>   
                                </div>
                            <div className="card-body p-3 align-items-center">
                            <table className="table">
                                <thead>
                                    <tr>
                                    <th scope="col">Product Name</th>
                                    <th scope="col">Current Stock</th>
                                    <th scope="col">Requested Stock</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {limitInventories && limitInventories.length > 0 ? limitInventories.map((limitInventory) => (
                                            <tr key={limitInventory.order_item_id}>
                                                <td>{limitInventory.description}</td>
                                                <td>{limitInventory.quantity}</td>
                                                <td>{limitInventory.orderedquantity}</td>
                                            </tr>
                                    )): <tr key={1}><td colSpan={3} className="text-center"> No Records Found</td></tr>}
                                </tbody>
                                </table>
                           </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-md-6 ">
                    <div className="card">
                        <div className="card-title mx-3">
                                    <h6>-</h6>
                                </div>
                            <div className="card-body p-3 d-flex align-items-center">
                            <PieChart
                                series={[
                                    {
                                    data: [
                                        { id: 0, value: 10, label: 'series A' },
                                        { id: 1, value: 15, label: 'series B' },
                                        { id: 2, value: 20, label: 'series C' },
                                    ],
                                    },
                                ]}
                                width={600}
                                height={260}
                                />
                           </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-6 col-md-6">
                        <div className="card" style={{minHeight:"370px"}}>
                        <div className="card-title mx-3">
                                    <h6>Upcoming Sales deliveries</h6>
                                     
                                </div>
                            <div className="card-body p-3 align-items-center">
                            <table className="table">
                                <thead>
                                    <tr>
                                    <th scope="col">Order Number</th>
                                    <th scope="col">Customer Location</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Delivery Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {upcomingSalesDeliveries && upcomingSalesDeliveries.length > 0 ? upcomingSalesDeliveries.map((upcomingSalesDelivery) => (
                                            <tr key={upcomingSalesDelivery.id}>
                                                <td>{upcomingSalesDelivery.order_number || 'N/A'}</td>
                                                <td>{upcomingSalesDelivery.location || 'N/A'}</td>
                                                <td><span className={"badge text-bg-"+upcomingSalesDelivery.status_bgcolor}>{upcomingSalesDelivery.order_status}</span></td>
                                                <td>{upcomingSalesDelivery.delivery_date}</td>
                                            </tr>
                                    )): <tr key={1}><td colSpan={3} className="text-center"> No Records Found</td></tr>}
                                </tbody>
                                </table>
                           </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-md-6 ">
                        <div className="card" style={{minHeight:"370px"}}>
                        <div className="card-title mx-3">
                                    <h6>Limited Stock (SO) </h6>
                                     
                                </div>
                            <div className="card-body p-3 align-items-center">
                            <table className="table">
                                <thead>
                                    <tr>
                                    <th scope="col">SKU</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Current Qty</th>
                                    <th scope="col">Required Qty</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {limitSaleInventories && limitSaleInventories.length > 0 ? limitSaleInventories.map((limitInventory) => (
                                            <tr key={limitInventory.product_id}>
                                                <td>{limitInventory.product_code}</td>
                                                <td>{limitInventory.product_description}</td>
                                                <td>{limitInventory.quantity}</td>
                                                <td>{limitInventory.orderedquantity}</td>
                                            </tr>
                                    )): <tr key={1}><td colSpan={4} className="text-center"> No Records Found</td></tr>}
                                </tbody>
                                </table>
                           </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12 col-md-12">
                        <div className="card" style={{minHeight:"370px"}}>
                        <div className="card-title mx-3">
                        <span className="float-start"><h6 className="text-dark">Most deliveries by Haulier (Transport)</h6></span>
                        <span className="float-end text-dark">
                        <DateRange startDate={startDate} endDate={endDate} onDateRangeChange={(res) => dateRangeFn(res)} placeholderText={'Date Range'}  />
                        </span>
                                     
                            </div>
                            <div className="card-body p-3 align-items-center">
                            <BarChart
                            series={mostdeliveriesByPartner.records || []}
                            xAxis={[{ data: mostdeliveriesByPartner.dates || [], scaleType: 'band' }]}
                            width={1200}
                            height={350}
                            slotProps={{
                                legend: {
                                  direction: 'row',
                                  position: { vertical: 'bottom', horizontal: 'middle' },
                                  padding: 0,
                                },
                              }}
                            />
                           </div>
                        </div>
                    </div>
                </div>
                {/* <div className="row">
                    <div className="col-xl-12 col-md-12">
                        <div className="card">
                        <div className="card-title mx-3">
                        <span className="float-start"><h6 className="text-dark">Popular Delivery Location</h6></span>
                            </div>
                            <div className="card-body p-3 d-flex align-items-center">
                            <BarChart
                            series={[
                                { data: [3, 4, 1, 6, 5], stack: 'A', label: 'Series A1' },
                                { data: [4, 3, 1, 5, 8], stack: 'A', label: 'Series A2' },
                                { data: [4, 2, 5, 4, 1], stack: 'B', label: 'Series B1' },
                                { data: [2, 8, 1, 3, 1], stack: 'B', label: 'Series B2' },
                                { data: [10, 6, 5, 8, 9], label: 'Series C1' },
                            ]}
                            width={1200}
                            height={350}
                            />
                           </div>
                        </div>
                    </div>
                </div> */}
                <div className="row">
                    <div className="col-xl-6 col-md-6">
                        <div className="card" style={{minHeight:"370px"}}>
                        <div className="card-title mx-3">
                                    <span className="float-start text-dark">
                                    <h6>Delayed Purchase Order Deliveries</h6> 
                                    </span>
                                </div>
                            <div className="card-body p-3 align-items-center">
                            <table className="table">
                                <thead>
                                    <tr>
                                    <th scope="col">Supplier Name</th>
                                    <th scope="col">Product Name</th>
                                    <th scope="col">Total Quantities</th>
                                    <th scope="col">Delivery Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {DelayedPO && DelayedPO.length > 0 ? DelayedPO.map((Delayed) => (
                                            <tr key={Delayed.id}>
                                                <td>{Delayed.supplier_name}</td>
                                                <td>{Delayed.description}</td>
                                                <td>{Delayed.quantity}</td>
                                                <td>{Delayed.delivery_date}</td>
                                            </tr>
                                    )): <tr key={1}><td colSpan={4} className="text-center"> No Records Found</td></tr>}
                                </tbody>
                                </table>
                           </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-md-6 ">
                        <div className="card">
                        <div className="card-title mx-3">
                                    <h6>Delayed Sales deliveries</h6>
                                </div>
                            <div className="card-body p-3 d-flex align-items-center">
                            <table className="table">
                                <thead>
                                    <tr>
                                    <th scope="col">Customer</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Delivery Date</th>
                                    <th scope="col">Dispatched Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {DelayedSO && DelayedSO.length > 0 ? DelayedSO.map((Delayed) => (
                                            <tr key={Delayed.id}>
                                                <td>{Delayed.client_name}</td>
                                                <td><Badge className={`badge bg-`+Delayed.status_bgcolor}>{Delayed.order_status}</Badge></td>
                                                <td>{Delayed.delivery_date}</td>
                                                <td>{Delayed.actual_delivery_date}</td>
                                            </tr>
                                    )): <tr key={1}><td colSpan={4} className="text-center"> No Records Found</td></tr>}
                                </tbody>
                                </table>
                           </div>
                        </div>
                    </div>
                </div>
            </section>
    </DefaultLayout>
    );
};
export default Dashboard;