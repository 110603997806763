import { BrowserRouter as Router,Routes, Route } from "react-router-dom";
import { Provider } from 'react-redux';
import store from './store/store';
import 'react-loading-skeleton/dist/skeleton.css'
import { ToastContainer } from 'react-toastify';
/*Routes*/
import Login from './pages/auth/Login';
import Forgot from "./pages/auth/Forgot";
import Dashboard from "./pages/dashboard";
import Profile from "./pages/others/Profile";
import WebPermissions from "./pages/others/WebPermissions";
import Permissions from "./pages/others/Permissions";
import Settings from "./pages/others/Settings";
import UserList from "./pages/user/List";
import EditUser from "./pages/user/EditUser";
import AddUser from './pages/user/AddUser';
import SuppliersList from "./pages/suppliers/List";
import EditSupplier from "./pages/suppliers/EditSupplier";
import AddSupplier from './pages/suppliers/AddSupplier';

import ClientList from "./pages/sales/clients/List";
import AddClient from "./pages/sales/clients/AddClient";
import EditClient from "./pages/sales/clients/EditClient";
import ClientAddressList from "./pages/sales/clients/Addresses";
import EditAddress from "./pages/sales/clients/EditAddress";

import TaxCodeList from "./pages/taxcodes/List";
import AddTaxCode from "./pages/taxcodes/AddTaxCode";
import EditTaxCode from "./pages/taxcodes/EditTaxCode";


import ProductList from "./pages/purchase/products/List";
import AddProduct from "./pages/purchase/products/AddProduct";
import EditProduct from "./pages/purchase/products/EditProduct";

import CreatePurchaseOrder from "./pages/purchase/orders/create";
import PurchaseOrderList from "./pages/purchase/orders";
import PurchaseOrderView from "./pages/purchase/orders/view";


import SaleProductList from "./pages/sales/products/List";
import AddSaleProduct from "./pages/sales/products/AddProduct";
import EditSaleProduct from "./pages/sales/products/EditProduct";

import TansportLogsList from "./pages/sales/transportlogs";

import SaleOrderList from "./pages/sales/orders";
import SaleOrderView from "./pages/sales/orders/view";
import CreateSaleOrder from "./pages/sales/orders/create";
import CategoryList from "./pages/purchase/categories/List";
import AddCategory from "./pages/purchase/categories/AddCategory";
import EditCategory from "./pages/purchase/categories/EditCategory";
import ProductionsList from "./pages/production/list";
import CreateProductionPlan from "./pages/production/create";
import ProductionPlanView from "./pages/production/view";

function App() {
  return (
    <Provider store={store}>
        <ToastContainer />
      <Router>
      <Routes>
          <Route path="/" exact={true} Component={Login} />
          <Route path="/forgot" exact={true} Component={Forgot} />
          <Route path="/dashboard" exact={true} Component={Dashboard} />
          <Route path="/profile" Component={Profile} />
          <Route path="/webpermissions" Component={WebPermissions} />
          <Route path="/permissions" Component={Permissions} />
          <Route path="/settings" Component={Settings} />

          <Route path="/users" Component={UserList} />
          <Route path="/users/:id" Component={EditUser}  />
          <Route path="/users/add" Component={AddUser}  />

          <Route path="/taxcodes" Component={TaxCodeList}  />
          <Route path="/taxcodes/:id" Component={EditTaxCode}  />
          <Route path="/taxcodes/add" Component={AddTaxCode}  />

          <Route path="/suppliers" Component={SuppliersList} />
          <Route path="/suppliers/:id" Component={EditSupplier}  />
          <Route path="/suppliers/add" Component={AddSupplier}  />

          <Route path="/clients" Component={ClientList} />
          <Route path="/clients/:id" Component={EditClient}  />
          <Route path="/clients/add" Component={AddClient}  />
          <Route path="/clients/addresslist" Component={ClientAddressList}  />
          <Route path="/clients/address/:id" Component={EditAddress}  />


          <Route path="/purchaseproducts" Component={ProductList}  />
          <Route path="/purchaseproducts/add" Component={AddProduct}  />
          <Route path="/purchaseproducts/:id" Component={EditProduct}  />

          <Route path="/purchaseorders" Component={PurchaseOrderList} />
            <Route path="/purchaseorders/add" Component={CreatePurchaseOrder} />
            <Route path="/purchaseorders/details/:id" Component={PurchaseOrderView} />
          
            <Route path="/products" Component={SaleProductList}  />
            <Route path="/products/add" Component={AddSaleProduct}  />
            <Route path="/products/:id" Component={EditSaleProduct}  />

            <Route path="/transport-logs" Component={TansportLogsList} />
         

            <Route path="/orders" Component={SaleOrderList} />
            <Route path="/orders/add" Component={CreateSaleOrder} />
            <Route path="/orders/details/:id" Component={SaleOrderView} />

            <Route path="/category" Component={CategoryList}  />
            <Route path="/category/add" Component={AddCategory}  />
            <Route path="/category/:id" Component={EditCategory}  />

            <Route path="/productionplans" Component={ProductionsList} />
            <Route path="/productionplans/create" Component={CreateProductionPlan} />
            <Route path="/productionplans/view/:id" Component={ProductionPlanView} />
      </Routes>
      </Router>
       </Provider>
  );
}

export default App;
