import React, { useEffect, useState,useRef} from "react";
import Table from "../../components/Table";
import DefaultLayout from "../../layouts/DefaultLayout";
import { getData,putData  } from "../../services/HttpServices";
import { setPageTitle,setBreadcrumbs } from '../../store/resourceSlice';
import { useDispatch } from 'react-redux';
import { AlertError,AlertSuccess } from "../../components/Alerts";
const SupplierList = () => {
    const searchRef = useRef("");
    const dispatch = useDispatch();


    const [supplierData,setSupplierData] = useState([])

    const [supplieCounts,setSupplieCounts] = useState(0)
    const [pageNo,setPageNo] = useState(1)
    const [isNext,setIsNext] = useState(false)
    const [limit,setLimit] = useState(10)
    const [search,setSearchData] = useState("")


    let tablColumns = {
        id:'ID',
        name:'Name',
        email:'Email',
        phone:'Phone',
        address_1:'Address'
    }
    const getSupplierData = (page, query='') =>{
        getData('/suppliers/list?page='+page+'&query='+query).then((response)=>{
            if(response.status === 200){
                let responseData = response.data
                setSupplierData(responseData.data.suppliers)
                setSupplieCounts(responseData.data.counts)
                setPageNo(responseData.data.page_no)
                setIsNext(responseData.data.is_next)
                setLimit(responseData.data.limit)
            }
        })
    }
    const paginationUpdate = (pageno) => {
        setPageNo(pageno)
        getSupplierData(pageno,search)
    }
    const filterResult = () =>{
        getSupplierData(1,search)
    }
    const updateSearch = (data) => {
        setSearchData(data)
    }
    const deleteRecord = (id) => {
         if (window.confirm("Are you sure wan't to delete.?"))
                    {
                        putData('/suppliers/delete/'+id).then((response)=>{
                            if(response.status === 200){
                                let resposnseData = response.data
                                if(resposnseData.success === false){
                                    AlertError(resposnseData.message)
                                    return;
                                }
                                AlertSuccess(resposnseData.message)
                                setPageNo(pageNo)
                                getSupplierData(pageNo)
                            }
            })
        }
    }
    useEffect(()=>{
        getSupplierData(pageNo)
        dispatch(setPageTitle('Suppliers'))
        dispatch(setBreadcrumbs([
            {
                label: 'Dashboard',
                link: '/dashboard',
                active: false, // true,false
                state: 'suppliers',
            },{
                label: 'Suppliers',
                link: '/suppliers',
                active: true, // true,false
                state: 'suppliers',
            }
        ]))
     // eslint-disable-next-line
    },[dispatch])
    const clearFilter = () => {
        setSearchData("")
        getSupplierData(1)
        searchRef.current.value = ''
    }
    return(
        <DefaultLayout>
        <section className="section">
            <div className="row">
                <div className="card">
                    <div className="card-body mt-5">
                        <Table data={{'list':supplierData, 'column': tablColumns, counts:supplieCounts, pageno: pageNo,limit:limit, isNext:isNext, 'editlink': '/suppliers/' }} updateFn = {paginationUpdate}   delFun ={deleteRecord} submitFilter ={filterResult} clearfilter={clearFilter}
                        filters={[         
                            {
                                label:"Find by name, email, phone no",
                                type:'search',
                                class:'form-control',
                                default:search,
                                ref:searchRef,
                                searchFilterFn:updateSearch
                            }
                        ]}
                        submenu={[
                                        {
                                            label:"Add Supplier",
                                            link:'/suppliers/add',
                                            buttonClass:'primary float-end'

                                        }
                                    ]} />
                        </div>
                        </div>
                        </div>
           </section>
        </DefaultLayout>
    )
}
export default SupplierList;