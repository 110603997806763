import React, { useEffect, useState,useRef } from "react";
import Table from "../../../components/Table";
import DefaultLayout from "../../../layouts/DefaultLayout";
import { getData } from "../../../services/HttpServices";
import { setPageTitle,setBreadcrumbs  } from '../../../store/resourceSlice';
import { useDispatch } from 'react-redux';
const SaleProductList = () => {

    const dispatch = useDispatch();
    const searchRef = useRef("");
    const [productData,setProductData] = useState([])

    const [productCounts,setProductCounts] = useState(0)
    const [pageNo,setPageNo] = useState(1)
    const [isNext,setIsNext] = useState(false)
    const [limit,setLimit] = useState(10)
    const [search,setSearchData] = useState('');
    const [clientData,setClientData] = useState([])
    const [selectedClient,setSelectedClient] = useState(0)
    let tablColumns = {
        id:'ID',
        code:'SKU',
        description:'Description',
        client_name:'Client',
        quantity:'Quantity',
        status:'Status'
    }
    const getProductData = (pageNo, query='') =>{
        let url = '/products/list?page='+pageNo+'&query='+query
        if(selectedClient){
            url += '&client='+selectedClient.value
        }
        getData(url).then((response)=>{
            if(response.status === 200){
                let responseData = response.data
                setProductData(responseData.data.products)
                setProductCounts(responseData.data.counts)
                setPageNo(responseData.data.page_no)
                setIsNext(responseData.data.is_next)
                setLimit(responseData.data.limit)
            }
        })
    }
    const paginationUpdate = (pageno) => {
        setPageNo(pageno)
        getProductData(pageno,search)
    }
    const filterResult = () =>{
        getProductData(1,search)
    }
    const ChangeClientHandler = (res) => {
        if(res)
         setSelectedClient(res)
        else
        setSelectedClient(null)
     };

    useEffect(()=>{
        getProductData(pageNo)
        dispatch(setPageTitle('Products'))
        getData('/clients/filter').then((response)=>{
            if(response.status === 200){
                let responseData = response.data
                setClientData(responseData.data)
            }
        })
        dispatch(setBreadcrumbs([
            {
                label: 'Dashboard',
                link: '/dashboard',
                active: false, // true,false
                state: '/products',
            },{
                label: 'Products',
                link: '/products',
                active: true, // true,false
                state: '/products',
            }
        ]))
     // eslint-disable-next-line
    },[dispatch])
    const onSearchChange = (data) => {
        setSearchData(data)
     };
     const clearFilter = () => {
        setSearchData("")
        getProductData(1)
        searchRef.current.value = ''
    }
    return(
        <DefaultLayout>
        <section className="section">
            <div className="row">
                <div className="card">
                    <div className="card-body mt-5">
                        <Table data={{'list':productData, 'column': tablColumns, counts:productCounts, pageno: pageNo,limit:limit, isNext:isNext, 'editlink': '/products/' }} updateFn = {paginationUpdate}
                        submitFilter={filterResult}  clearfilter={clearFilter}
                        filters={[         
                            {
                                label:"Find by Code, Description",
                                type:'search',
                                class:'form-control',
                                default:search,
                                ref:searchRef,
                                searchFilterFn:onSearchChange
                            },{
                                label:"Select Client",
                                type:"select",
                                class:'form-control',
                                options:clientData,
                                default:selectedClient,
                                changeOptionsFn:ChangeClientHandler,
                                isClearable:true
                            },
                        ]}
                        
                        
                        submenu={[
                                        {
                                            label:"Add New Product",
                                            link:'/products/add',
                                            buttonClass:'primary float-end'

                                        }
                                    ]}/> 
                        </div>
                        </div>
                        </div>
           </section>
        </DefaultLayout>
    )
}
export default SaleProductList;