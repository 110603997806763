import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DefaultLayout from "../../../layouts/DefaultLayout";
import { getData, putData } from "../../../services/HttpServices";
import {
  AlertError,
  AlertSuccess,
  AlertWarning,
} from "../../../components/Alerts";
import { setPageTitle,setBreadcrumbs } from "../../../store/resourceSlice";
import { useDispatch } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { Invoice } from "./invoice";
import { PrimaryButton } from "../../../components/Button";
const PurchaseOrderView = () => {
  const componentRef = useRef();
  const dispatch = useDispatch();

  /*Fetch user id */
  const navigate = useNavigate();
  const { id } = useParams();
  /*Inital state data*/
  const [orderData, setOrderData] = useState(null);
  const [orderItems, setOrderItems] = useState([]);
  const [history, setHistory] = useState([]);
  const [webInformation, setWebInformation] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  /*Get order data*/
  const getOrderData = () => {
    getData("/purchase/orders/details/" + id).then((response) => {
      if (response.status === 200) {
        let responseData = response.data;
        if (responseData.success) {
          setOrderData(responseData.data);
          setOrderItems(responseData.data.items);
          setWebInformation(responseData.data.web_information);
          setHistory(responseData.data.history);
        } else {
          AlertError(responseData.message);
          navigate("/purchaseorders");
        }
      }
    });
  };
  const updateRowHandler = () => {
    if (!isUpdate) {
      getOrderData();
    }
    setIsUpdate(!isUpdate);
  };
  useEffect(() => {
    getOrderData();
    dispatch(setPageTitle("Purchase Order Details"));
    dispatch(setBreadcrumbs([
      {
          label: 'Dashboard',
          link: '/dashboard',
          active: false, // true,false
          state: '/purchaseorders/details',
      },{
          label: 'Purchase Orders',
          link: '/purchaseorders',
          active: false, // true,false
          state: '/purchaseorders/details',
      },{
        label: 'Purchase Order Details',
        link: '/purchaseorders/details/'+id,
        active: true, // true,false
        state: '/purchaseorders/details',
    }
    ]))
    // eslint-disable-next-line
  }, [id, navigate, dispatch]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const rowUpdatehandler = (index, value, type) => {
    let items = [...orderItems];
    items[index][type] = value;
    setOrderItems(items);
  };
  const updateRowItemsHandler = () => {
    if (window.confirm("Are you sure wan't to update items?")) {
      putData("/purchase/orders/updateitems", orderData.items).then(
        (response) => {
          if (response.status === 200) {
            let responseData = response.data;
            setIsUpdate(false);
            if (responseData.success) {
              AlertSuccess(responseData.message);
              getOrderData();
              return;
            }
            AlertWarning(responseData.message);
          }
        }
      );
    }
  };
  const approveHandler = () => {
    if (window.confirm("Are you sure wan't to mark complete this order?")) {
      putData("/purchase/orders/complete", { id: id }).then((response) => {
        if (response.status === 200) {
          let responseData = response.data;
          if (responseData.success) {
            AlertSuccess(responseData.message);
            getOrderData();
            return;
          }
          AlertWarning(responseData.message);
        }
      });
    }
  };
  return (
    <DefaultLayout>
      <section className="section">
        <div className="row">
          <div className="card">
            <div className="card-body">
              {orderData && (
                <div className="container mb-5 mt-3">
                  <div className="row d-flex align-items-baseline">
                    <div className="col-xl-6">
                      <h5>
                        {orderData.delivery_name
                          ? orderData.delivery_name
                          : "N/A"}
                      </h5>
                    </div>
                    <div className="col-xl-6">
                      <PrimaryButton
                        clickfun={handlePrint}
                        buttonClass="primary view-btn float-end no-print"
                        buttonText="Print"
                        iclassName="file-earmark-pdf-fill"
                      />
                      <div className={"hide"}>
                        <Invoice
                          ref={componentRef}
                          orderData={orderData}
                          orderItems={orderItems}
                          webInformation={webInformation}
                        />
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="container">
                    <div className="col-md-12">
                      <div className="text-center">
                        <p className="pt-0">GLN No : {orderData.gln_no}</p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-xl-12 text-end">
                        <b>Invoice Address</b>
                        <br />
                        {orderData.delivery_name}
                        <br />
                        {orderData.delivery_address_1},
                        {orderData.delivery_address_2}
                        <br />
                        {orderData.delivery_address_3} ,{" "}
                        {orderData.delivery_address_4}
                        <br />
                        {orderData.delivery_post_code}
                        <br />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-6">
                        <address>
                          <b>{webInformation.owner_name}</b>
                          <br />
                          {webInformation.address}
                        </address>
                      </div>
                      <div className="col-xl-6 text-end">
                        <address>
                          <b>Delivery Address</b>
                          <br />
                          {orderData.delivery_address_1},
                          {orderData.delivery_address_2}
                          <br />
                          {orderData.delivery_address_3} ,{" "}
                          {orderData.delivery_address_4}
                          <br />
                          {orderData.delivery_post_code}
                          <br />
                        </address>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-xl-6">
                        <p className="mb-0">
                          <b>To :</b>{" "}
                          {orderData.delivery_name
                            ? orderData.delivery_name
                            : "N/A"}{" "}
                        </p>
                        <p className="mb-0">
                          <b>Phone :</b>{" "}
                          {orderData.supplier_contact_number
                            ? orderData.supplier_contact_number
                            : "N/A"}{" "}
                        </p>
                        <p className="mb-0">
                          <b>Fax :</b> {orderData.fax ? orderData.fax : "N/A"}{" "}
                        </p>
                        <p className="mb-0">
                          <b>Sup. Ref :</b>{" "}
                        </p>
                        <p className="mb-0">
                          <b>Batch :</b>{" "}
                        </p>
                      </div>
                      <div className="col-xl-6 text-end">
                        <p className="mb-0">
                          <b>From :</b> {webInformation.owner_name}{" "}
                        </p>
                        <p className="mb-0">
                          <b>Phone :</b> {webInformation.phone}{" "}
                        </p>
                        <p className="mb-0">
                          <b>Fax :</b> {webInformation.fax}
                        </p>
                        <p className="mb-0">
                          <b>Mail :</b> {webInformation.email}{" "}
                        </p>
                      </div>
                    </div>
                    <div className="row my-2 mx-1 justify-content-center">
                      <h4 className="text-center mb-3 mt-3">Purchase Order</h4>
                      <table className="table table-striped table-borderless">
                        <thead className="text-white">
                          <tr>
                            <th scope="col">
                              <strong>No</strong>
                            </th>
                            <th scope="col">
                              <strong>Description</strong>
                            </th>
                            <th scope="col">
                              <strong>GTIN No</strong>
                            </th>
                            <th scope="col">
                              <strong>No Of Units</strong>
                            </th>
                            <th scope="col">
                              <strong>Delivery</strong>
                            </th>
                            <th scope="col">
                              <strong>Purchase Order No</strong>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {orderItems.length > 0 &&
                            orderItems.map((item, index) => (
                              <React.Fragment key={index}>
                                {!isUpdate && (
                                  <tr
                                    key={index}
                                    className={
                                      orderData.status_id === 4
                                        ? "bg-danger text-white"
                                        : ""
                                    }
                                  >
                                    <th scope="row"> {index + 1} </th>
                                    <td>{item.description} </td>
                                    <td>{item.gtin_no} </td>
                                    <td>{item.unit_quantity}</td>
                                    <td>{item.delivery_date}</td>
                                    <td>{orderData.id}</td>.
                                  </tr>
                                )}
                                {isUpdate && (
                                  <tr key={index}>
                                    <th scope="row"> {index + 1} </th>
                                    <td>{item.description} </td>
                                    <td>{item.gtin_no} </td>
                                    <td className="col-2">
                                      {" "}
                                      <input
                                        type="number"
                                        min={1}
                                        className="form-control "
                                        defaultValue={item.unit_quantity}
                                        onInput={(e) =>
                                          rowUpdatehandler(
                                            index,
                                            e.target.value,
                                            "unit_quantity"
                                          )
                                        }
                                      />
                                    </td>
                                    <td>{item.delivery_date}</td>
                                    <td>{orderData.id}</td>
                                  </tr>
                                )}
                              </React.Fragment>
                            ))}

                          <tr key={0}>
                            <td colSpan="7">
                              {orderData.status_id === 1 && (
                                <React.Fragment>
                                  {isUpdate && (
                                    <PrimaryButton
                                      buttonText="Return"
                                      buttonClass="warning float-end"
                                      title="Cancel"
                                      clickfun={() => updateRowHandler()}
                                      iclass="arrow-counterclockwise"
                                    />
                                  )}
                                  {isUpdate && (
                                    <PrimaryButton
                                      buttonText="Update Items"
                                      buttonClass="danger float-end"
                                      title="Update Items"
                                      clickfun={() => updateRowItemsHandler()}
                                      iclass="database"
                                    />
                                  )}
                                  {!isUpdate && orderData.status_id === 1 && (
                                    <PrimaryButton
                                      buttonText="Edit Items"
                                      buttonClass="success float-end"
                                      title="Edit Items"
                                      clickfun={() => updateRowHandler()}
                                      iclass="pencil-fill"
                                    />
                                  )}
                                </React.Fragment>
                              )}
                              {!isUpdate && orderData.status_id === 2 && (
                                <React.Fragment>
                                  <PrimaryButton
                                    buttonText="Complete Order"
                                    buttonClass="success float-end"
                                    title="Complete Order"
                                    clickfun={() => approveHandler()}
                                    iclass="check-all"
                                  />
                                </React.Fragment>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    {history.length > 0 &&
                    <div className="row">
                      <div className="col-xl-12">
                        <h5 className="card-title">Order History Detail</h5>
                        <ul className="timeline">
                        
                            { history.map((his, index) => ( <li className="timeline-item mb-5" key={index}>
                            <h6>{his.heading}</h6>
                            <small>{his.created}</small>
                            <p>{his.description}
                            
                            </p>
                          </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                     }
                  </div>
               
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </DefaultLayout>
  );
};
export default PurchaseOrderView;
