import Select from "react-select";
export default function SelectOptions(props){
    return (
      <Select
        className={props.className}
        options={props.options}
        value={props.default}
        onChange={(res)=> props.changeFn(res)}
        isSearchable={true}
        isMulti={props.isMulti}
        isClearable={props.isClearable}
        placeholder={props.placeholder}
      />
    );
}