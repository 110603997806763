import React, { useEffect } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import { useDispatch,useSelector } from 'react-redux';
import { fetchCurrentLogin } from '../store/resourceSlice';
import { Link } from "react-router-dom";
const DefaultLayout = ({ children }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchCurrentLogin())
    }, [dispatch]);

    const pageTitle = useSelector((state) => state.currentData.pageTitle);
    const breadcrumbs = useSelector((state) => state.currentData.breadcrumbs);
   
    return (
         <>
        <Header/> 
        <Sidebar />
        <main id="main" className="main">
        <div className="pagetitle">
            <h1>{pageTitle}</h1>
            {breadcrumbs.length > 0 ? 
            <nav>
                <ol className="breadcrumb">
                {breadcrumbs.map((data, index) => (
                <li className={`breadcrumb-item ${data.active ? "active" : " collapsed"}`} key={index}><Link to={data.link} state={data.state}>{data.label}</Link></li>
                ))}
                </ol>
            </nav>
            :""}
        </div>
        <div id="wrapper">
            {children}
        </div>
    </main>
        <Footer />
        </>
    )
}

export default DefaultLayout;