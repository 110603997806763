import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/DefaultLayout";
import { getData, postData } from "../../services/HttpServices"
import { AlertSuccess, AlertError } from "../../components/Alerts"
import { useForm } from 'react-hook-form';
import Skeleton from "react-loading-skeleton";
import { setPageTitle,setBreadcrumbs } from '../../store/resourceSlice';
import { useDispatch } from 'react-redux';
const Settiings = () => {
    const dispatch = useDispatch();
    const [settingData, setSettingData] = useState(null);
    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = data => {
        postData('/settings/update', data).then((response) => {
            let resposnseData = response.data
            if (resposnseData.success === false) {
                AlertError(resposnseData.message);
                return
            }
            AlertSuccess(resposnseData.message)
        }).catch(e => {
            AlertError(e);
        })
    }

    useEffect(() => {
        /*Get user data*/
        const getSettingData = () => {
            getData('/settings').then((response) => {
                if (response.status === 200) {
                    let responseData = response.data
                    if (responseData.success) {
                        setSettingData(responseData.data)

                    }
                }
            })
        }
        getSettingData()
        dispatch(setPageTitle('Settings'))
        dispatch(setBreadcrumbs([
            {
                label: 'Dashboard',
                link: '/dashboard',
                active: false, // true,false
                state: '/settings',
            },{
                label: 'Settings',
                link: '/settings',
                active: true, // true,false
                state: '/settings',
            }
          ]))
    }, [dispatch])

    return (
        <DefaultLayout>
        <section className="section">
            <div className="row">
                <div className="card">
                              <div className="card-body">
                    <div className="container py-5">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                    <div className="col-lg-12 col-xl-12">
                                <h5 className="card-title">Settings</h5>
                                {settingData ? 
                        <form onSubmit={handleSubmit(onSubmit)}>
                           <div className="row">
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="name" className="col-sm-12 col-form-label">Company Name<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="name" defaultValue={settingData.name} {...register("name", {required: true , maxLength: 128})}/>
                                        {errors?.name && errors.name.type === "required" && <span className="error">Please enter name</span>}
                                        {errors?.name && errors.name.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                </div>  
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="address" className="col-sm-12 col-form-label">Company Address<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="address" defaultValue={settingData.address} {...register("address", {required: true , maxLength: 128})}/>
                                        {errors?.address && errors.address.type === "required" && <span className="error">Please enter address</span>}
                                        {errors?.address && errors.address.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                </div>  
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="email" className="col-sm-12 col-form-label">Company Email<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="email" defaultValue={settingData.email}  {...register("email", {required: true , maxLength: 128})}/>
                                        {errors?.email && errors.email.type === "required" && <span className="error">Please enter email</span>}
                                        {errors?.email && errors.email.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="phone" className="col-sm-12 col-form-label">Company Phone<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="phone" defaultValue={settingData.phone}  {...register("phone", {required: true , maxLength: 128})}/>
                                        {errors?.phone && errors.phone.type === "required" && <span className="error">Please enter phone</span>}
                                        {errors?.phone && errors.phone.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="fax" className="col-sm-12 col-form-label">Company Fax<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="fax" defaultValue={settingData.fax}  {...register("fax", {required: true , maxLength: 128})}/>
                                        {errors?.fax && errors.fax.type === "required" && <span className="error">Please enter fax</span>}
                                        {errors?.fax && errors.fax.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="notification_emails" className="col-sm-12 col-form-label">Notifictions Emails<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="notification_emails" defaultValue={settingData.notification_emails} {...register("notification_emails", {required: true})}/>
                                        <small className="text-danger">Please use (,) comma for multiple emails.eg: john@email.com, jane@email.com</small>
                                        {errors?.notification_emails && errors.notification_emails.type === "required" && <span className="error">Please enter emails</span>}
                                    </div>
                                </div>  
                                <div className="text-center">
                                    <button type="submit" className="btn btn-primary sub-btn">Submit</button>
                                </div>    </div>
                            </form>
:<Skeleton style={{
    width: '100%',
    marginBottom:5,
    marginTop:5,
    height:20
  }} count={10} inline={true} />}
                        </div>
                        </div>
                        </div>   </div>
                        </div>
                        </div>
           </section>
        </DefaultLayout>
    )
}
export default Settiings;