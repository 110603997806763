import React, { useEffect, useState } from "react"
import DefaultLayout from "../../../layouts/DefaultLayout"
import { useNavigate, useParams } from "react-router-dom"
import { getData, putData } from "../../../services/HttpServices"
import { AlertError, AlertSuccess } from "../../../components/Alerts"
import { useForm } from 'react-hook-form';
import Skeleton from "react-loading-skeleton"
import { setPageTitle,setBreadcrumbs } from '../../../store/resourceSlice';
import { useDispatch } from 'react-redux';
const EditProduct = () => {

    const dispatch = useDispatch();
    /*Fetch product id */
    const navigate = useNavigate()
    const { id } = useParams()
    /*Inital state data*/
    const [productData, setProductData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [categoryData, setCategoryData] = useState([])
    const [history, setHistory] = useState([])
    /*Update product Data*/
    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = data => {
        putData('/purchaseproducts/update/' + id, data).then((response) => {
            let resposnseData = response.data
            if (resposnseData.success === false) {
                AlertError(resposnseData.message);
                return
            }
            AlertSuccess(resposnseData.message)
            navigate("/purchaseproducts");

        }).catch(e => {
            AlertError(e);
        })
    }
  

    useEffect(() => {
        /*Get product data*/
        getData("/category/filter").then((response) => {
            if (response.status === 200) {
                let responseData = response.data;
                if (responseData.success) {
                  setCategoryData(responseData.data);
                  
                }
            }
            getProductData()
        });
        const getProductData = () => {
            getData('/purchaseproducts/details/' + id).then((response) => {
                if (response.status === 200) {
                    let responseData = response.data
                    if (responseData.success) {
                        setProductData(responseData.data.product)
                        setHistory(responseData.data.history);
                        setIsLoading(true)
                    } else {
                        AlertError(responseData.message)
                        navigate('/purchaseproducts')
                    }
                }
            })
        }
        
        dispatch(setPageTitle('Edit Product'))
        dispatch(setBreadcrumbs([
            {
                label: 'Dashboard',
                link: '/dashboard',
                active: false, // true,false
                state: '/purchaseproducts/edit',
            },{
                label: 'Purchase Products',
                link: '/purchaseproducts',
                active: false, // true,false
                state: '/purchaseproducts/edit',
            },{
              label: 'Edit Product',
              link: '/purchaseproducts/'+id,
              active: true, // true,false
              state: '/purchaseproducts/edit',
          }
          ]))
    }, [id, navigate, dispatch])
   
    return (
        <DefaultLayout>
        <section className="section">
            <div className="row">
                <div className="card">
                    <div className="card-body">


<div className="container py-5 h-100">
<div className="row d-flex justify-content-center align-items-center h-100">

<div className="col-lg-12 col-xl-12">


                        {isLoading ?
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                            <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="ean_no" className="col-sm-12 col-form-label">Code<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="code" defaultValue={productData && productData.code} {...register("code", {disabled: true , maxLength: 20})} />
                                    </div>
                                   
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="postcode" className="col-sm-12 col-form-label">Category<span className="text-danger">*</span>
                                    </label>
                                    <div className="col-sm-12">
                                    
                                       <select id="category" className="form-control" defaultValue={productData && productData.category_id} {...register("category", {required: true })}>
                                       <option key={0} value={0}>Select a category</option>
                                            {categoryData.length && categoryData.map((category, index) => (
                                            <option key={index} value={category.value}>{category.label}</option>
                                        ))}
                                         {errors?.category && <span className="error">Please select a category</span>}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="gtin_no" className="col-sm-12 col-form-label">GTIN No<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="gtin_no" defaultValue={productData && productData.gtin_no} {...register("gtin_no", {required: true , maxLength: 13})} />
                                        {errors?.gtin_no && errors?.gtin_no.type === "required" && <span className="error">Please enter gtin_no</span>}
                                        {errors?.gtin_no && errors?.gtin_no.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                   
                                </div>
                                <div className="col-lg-6 col-xl-6  mb-3">
                                    <label htmlFor="description_1" className="col-sm-12 col-form-label">Description<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="description_1" defaultValue={productData.description_1}  {...register("description_1", {required: true , maxLength: 125})} />
                                        {errors?.description_1 && errors?.description_1.type === "required" && <span className="error">Please enter description</span>}
                                        {errors?.description_1 && errors?.description_1.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                   
                                </div>
                                {/* <div className="col-lg-6 col-xl-6  mb-3">
                                    <label htmlFor="description_2" className="col-sm-12 col-form-label">Description 2<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="description_2" defaultValue={productData.description_2}  {...register("description_2", {required: false , maxLength: 125})} />
                                        {errors?.description_2 && errors?.description_2.type === "required" && <span className="error">Please enter description</span>}
                                        {errors?.description_2 && errors?.description_2.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                   
                                </div> */}
                                <div className="col-lg-6 col-xl-6  mb-3">
                                    <label htmlFor="size" className="col-sm-12 col-form-label">Size/Pallet<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="number" className="form-control" id="size" defaultValue={ productData.size }  {...register("size", {required: true})}/>
                                        {errors?.size && errors?.size.type === "required" && <span className="error">Please enter size</span>}
                                    </div>
                                   
                                </div>   
                                <div className="col-lg-6 col-xl-6  mb-3">
                                    <label htmlFor="quantity" className="col-sm-12 col-form-label">Quantity<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="number" className="form-control" id="quantity" defaultValue={ productData.quantity }  {...register("quantity", {required: true})}/>
                                        {errors?.quantity && errors?.quantity.type === "required" && <span className="error">Please enter quantity</span>}
                                    </div>
                                   
                                </div> 
                                {/* <div className="col-lg-6 col-xl-6  mb-3">
                                    <label htmlFor="unit_price" className="col-sm-12 col-form-label">Unit Price</label>
                                    <div className="col-sm-12">
                                        <input type="number" className="form-control" step={0.01} id="unit_price" defaultValue={ productData.unit_price } {...register("unit_price", {required: false, maxLength: 30})}/>
                                        {errors?.unit_price && errors?.unit_price.type === "required" && <span className="error">Please enter unit price</span>}
                                    </div>
                                </div>  */}
                                <div className="text-center">
                                    <button type="submit" className="btn btn-primary sub-btn">Submit</button>
                                </div>
                                </div>
                            </form>
:<Skeleton style={{
    width: '100%',
    marginBottom:5,
    marginTop:5,
    height:20
  }} count={10} inline={true} />}
                        </div>
                        {history.length > 0 &&
                      <div className="col-xl-12">
                        <h5 className="card-title">History Detail</h5>
                        <ul className="timeline">
                        
                            { history.map((his, index) => ( <li className="timeline-item mb-5" key={index}>
                            <h6>{his.heading}</h6>
                            <small className="mb-5">{his.created}</small>
                            <div className="col-xl-12" dangerouslySetInnerHTML={{ __html: his.description }}></div>
                          </li>
                            ))}
                        </ul>
                      </div>
                     }
                        </div>
                        
                        </div>
                               </div>
                        </div>
                        </div>
           </section>
        </DefaultLayout>
    )
}
export default EditProduct;