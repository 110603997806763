import React, { useEffect } from "react";
import AuthLayout from "../../layouts/AuthLayout";
import { useForm } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
import { postData, getData } from "../../services/HttpServices";
import { AlertSuccess, AlertError } from "../../components/Alerts";

const Login = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const UserToken = localStorage.getItem('cmw-user-token');
        if (UserToken) {
            const getCurrentUser = () => {
                getData('/currentloggedin', ).then((response) => {
                    let userData = response.data
                    if (response.status === 200) {
                        if (userData.success) {
                            navigate('/dashboard')
                        } else {
                            AlertError(response.message)
                        }
                    } else {
                        AlertError(response.message)
                    }
                })
            }
            getCurrentUser()

        }
    }, [navigate])

    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = data => {
        postData('/login', data).then((response) => {
            let resposnseData = response.data
            if (resposnseData.success === false) {
                AlertError(resposnseData.message);
                return
            }
            localStorage.setItem("cmw-user-token", resposnseData.data);
            AlertSuccess(resposnseData.message)
            setTimeout(function() {
                navigate('/dashboard')
            }, 1000)

        }).catch(e => {
            AlertError(e);
        })
    }
    return (
        <AuthLayout>
            <div className="card-body">
                        <div className="pt-4 pb-2">
                            <h5 className="card-title text-center pb-0 fs-4">Login to Your Account</h5>
                            <p className="text-center small">Enter your username &amp; password to login</p>
                        </div>
                        <form className="row g-3" onSubmit={handleSubmit(onSubmit)}>
                            <div className="col-12">
                                <label htmlFor="username" className="form-label">Username</label>
                                <div className="input-group has-validation">
                                    <span className="input-group-text" id="inputGroupPrepend">@</span>
                                    <input type="text" name="username" className="form-control" placeholder="User Name"  {...register("username", {required: true})}  />
                                    {errors?.username &&  <div className="text-danger">Please enter your username.</div>}
                                   
                                </div>
                            </div>
                            <div className="col-12">
                                <label htmlFor="password" className="form-label">Password</label>
                                <input type="password" name="password" className="form-control" placeholder="Password" {...register("password", {required: true , maxLength: 20})} />
                                {errors?.password && errors.password.type === "required" && <div className="text-danger">Please enter your password!</div>}
                                 {errors?.password && errors.password.type === "maxLength" && <div className="text-danger">Max length exceeded</div>}
                                
                            </div>
                            <div className="col-12">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="remember" value="true" id="rememberMe" />
                                    <label className="form-check-label" htmlFor="rememberMe">Remember me</label>
                                </div>
                            </div>
                            <div className="col-12">
                                <button className="btn btn-primary w-100" type="submit">Login</button>
                            </div>
                        </form>
                    </div>
        </AuthLayout>
    )
}

export default Login;