import React, { useEffect, useState,useRef } from "react";
import Table from "../../../components/Table";
import DefaultLayout from "../../../layouts/DefaultLayout";
import { getData } from "../../../services/HttpServices";
import { useLocation   } from 'react-router-dom';
import { setPageTitle,setFilters,setBreadcrumbs } from '../../../store/resourceSlice';
import { useDispatch,useSelector } from 'react-redux';
const SaleOrderList = () => {
    const searchRef = useRef("");
    const dispatch = useDispatch();
    const {state} = useLocation();
    const filters = useSelector((state) => state.currentData.filters);
    const [orderData,setOrderData] = useState([])
    const [orderCounts,setOrderCounts] = useState(0)
    const [pageNo,setPageNo] = useState(filters.pageno || 1)
    const [isNext,setIsNext] = useState(false)
    const [limit,setLimit] = useState(10)
    const [search,setSearchData] = useState(filters.search || '')
    const [startDate, setStartDate] = useState((filters.date && filters.date.start) || null);
    const [endDate, setEndDate] = useState( (filters.date && filters.date.end) || null);
    const [selectedStatus, setSelectedStatus] = useState((filters.defaultStatus && filters.defaultStatus.value) ||  null);
    const [defaultStatus, setDefaultStatus] = useState(filters.defaultStatus || null);
    const [statusData, setStatusData] = useState([]);
    const [clientData,setClientData] = useState([])
    //const [supplierData,setSupplierData] = useState([])
    const [selectedClient,setSelectedClient] = useState(filters.selectedClient || 0)
    let tablColumns = {
        id:'ID',
        order_number:'Order Number',
        client_name:'Client Name',
        delivery_date:'Delivery Date',
        location:'Location',
        total_quantity:'Quantity',
        total_amount:'Amount',
        order_status:'Status',
    }
    const getOrderData = (pageNo, query='',date='',status='', client='') =>{
      
        let url = '/orders/list?page='+pageNo+'&query='+query+'&client='+client;
        if(date){
            url +='&start='+date.start+'&end='+date.end
        }
        if(status){
            url +='&status='+status
        }
        getData(url).then((response)=>{
            if(response.status === 200){
                let responseData = response.data
                setOrderData(responseData.data.orders)
                setOrderCounts(responseData.data.counts)
                setPageNo(responseData.data.page_no)
                setIsNext(responseData.data.is_next)
                setLimit(responseData.data.limit)
            }
        })
    }
    const paginationUpdate = (pageno) => {
        // const new_obj = { ...filters, [pageNo]: pageno }
        // dispatch(setFilters(new_obj))
        setPageNo(pageno)
        let date = {
            'start': (startDate) ?  startDate.toLocaleDateString("en-UK") : "",
            'end': (endDate) ? endDate.toLocaleDateString("en-UK") : "",
         }
         let client = ''
         if(selectedClient)
            client = selectedClient.value
         const filter = {
            pageno, search, date, defaultStatus, selectedClient
         }
         dispatch(setFilters(filter))
        getOrderData(pageno,search,date,selectedStatus,client)
    }
    const filterResult = () =>{
        let date = {
            'start': (startDate) ?  startDate.toLocaleDateString("en-UK") : "",
            'end': (endDate) ? endDate.toLocaleDateString("en-UK") : "",
         }
         let client = ''
         if(selectedClient)
            client = selectedClient.value

         let pageno=pageNo
         const filter = {
            pageno, search, date, defaultStatus, selectedClient
         }
         dispatch(setFilters(filter))
         getOrderData(pageNo,search,date,selectedStatus,client)
    }
    const clearFilter = () => {
        setSelectedStatus(null)
        setDefaultStatus(null)
        setSearchData("")
        getOrderData(1)
        setSelectedClient(null)
        searchRef.current.value = ''
        const filter = {}
        dispatch(setFilters(filter))
    }
    const onSearchChange = (data) => {
        setSearchData(data)
     };
     const changeStatus = (data)=>{
        if(data) {
            setSelectedStatus(data.value)
            setDefaultStatus(data)
         } else{
            setSelectedStatus(null)
            setDefaultStatus(null)
         }
     }
     const onDateRangeChange = (dates) => {
         const [start, end] = dates;
         setStartDate(start);
         setEndDate(end);
     };
     const ChangeClientHandler = (res) => {
        if(res)
         setSelectedClient(res)
        else
            setSelectedClient(null)
     };

    useEffect(()=>{
        getData('/orders/status').then((response)=>{
            if(response.status === 200){
                let responseData = response.data
                setStatusData(responseData.data)
            }
        })
        getData('/clients/filter').then((response)=>{
            if(response.status === 200){
                let responseData = response.data
                setClientData(responseData.data)
            }
        })
        dispatch(setPageTitle('Sale Orders'))
        dispatch(setBreadcrumbs([
            {
                label: 'Dashboard',
                link: '/dashboard',
                active: false, // true,false
                state: '/orders',
            },{
                label: 'Sale Orders',
                link: '/orders',
                active: true, // true,false
                state: '/orders',
            }
        ]))
        if(state === '/orders/details'){
            filterResult();
        }else{
            clearFilter();
        }
        
    // eslint-disable-next-line
    },[dispatch,state])
    return(
        <DefaultLayout>
            
        <section className="section">
            <div className="row">
                <div className="card">
                    <div className="card-body mt-5">
                        <Table data={{'list':orderData, 'column': tablColumns, counts:orderCounts, pageno: pageNo,limit:limit, isNext:isNext, 'viewLink': '/orders/details/'}} updateFn = {paginationUpdate} 
                        submitFilter={filterResult}
                        clearfilter={clearFilter}
                    
                        filters={[         
                            {
                                label:"Find by Order Number",
                                type:'search',
                                class:'form-control',
                                default:search,
                                ref:searchRef,
                                searchFilterFn:onSearchChange
                            },{
                                label:"Date Range",
                                type:'daterange',
                                class:'form-control',
                                startDate:startDate,
                                endDate:endDate,
                                placeholderText:'Select Date Range',
                                dateRangeFn:onDateRangeChange
                            },
                            {
                                label:"Select Client",
                                type:"select",
                                class:'form-control',
                                options:clientData,
                                default:selectedClient,
                                changeOptionsFn:ChangeClientHandler,
                                isClearable:true
                            },
                            {
                                label:"Select status",
                                type:"select",
                                class:'form-control',
                                options:statusData,
                                default:defaultStatus,
                                changeOptionsFn:changeStatus,
                                isClearable:true
                            }
                        ]}
                        
                        submenu={[
                                        {
                                            label:"New",
                                            link:'/orders/add',
                                            buttonClass:'primary float-end '

                                        }
                                    ]}/> 
                        </div>
                        </div>
             </div>
           </section>
        </DefaultLayout>
    )
}
export default SaleOrderList;