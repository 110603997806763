import React, { useState, useEffect } from "react";
import DefaultLayout from "../../../layouts/DefaultLayout";
import { useNavigate } from "react-router-dom"
import { getData,postData } from "../../../services/HttpServices";
import { PrimaryButton, DeleteButton } from "../../../components/Button";
import { AlertError,AlertSuccess } from "../../../components/Alerts";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { setPageTitle ,setBreadcrumbs } from '../../../store/resourceSlice';
import { useDispatch } from 'react-redux';
import Addaddresspopup from "../clients/addresspopup";
const CreateSaleOrder = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()


  const [clientId, setClientId] = useState(0);
  const [selectedClient, setSelectedClient] = useState(null);
  const [addressId, setAddressId] = useState(0);
  const [deliveryDate, setDeliveryDate] = useState("");
  const [instructions, setInstructions] = useState("");
  const [show, setShow] = useState(false);

  const [clientData, setClientData] = useState([]);
  
  const [addressData, setAddressData] = useState([]);
  const [productRows, setProductRows] = useState([{ product_id: 0,quantity: 0,pack:0,unit_price:0 }]);
  const [productData, setProductData] = useState([]);

  const [companyData, setCompanyData] = useState([]);
  const [companyObj, setCompanyObj] = useState();
  const [transportDate, setTransportDate] = useState();
  const [trailer, setTrailer] = useState();



  const onSubmit = () => {
    if (!clientId) {
      AlertError("Please choose a client");
      return;
    }
    if (!addressId) {
      AlertError("Please choose a address");
      return;
    }
    if (!deliveryDate) {
      AlertError("Please select a delivery date");
      return;
    }
    if (!companyObj) {
      AlertError("Please select a Transport Company");
      return;
    }
    if (!transportDate) {
      AlertError("Please select a Transport Date & Time");
      return;
    }
    if (productRows.length === 0) {
      AlertError("Please add a product.");
      return;
    }
    let errors = productRows.filter((res) => {
      return res.product_id === 0 || res.quantity === 0 || (res.pack % 1 !== 0)
    });
    if (errors.length > 0) {
      AlertError("The product section required attention.");
      return;
    }
    let newDeliveryDate = deliveryDate.toLocaleDateString("en-UK");
   
    const postArr = {
        deliverydate: newDeliveryDate,
        client: clientId,
        addressId: addressId,
        instructions: instructions,
        productRows: productRows,
        trailer:trailer,
        transport_date:transportDate,
        company:companyObj['value']
    }
    postData('/order',postArr).then((response) => {
        let resposnseData = response.data
        if(resposnseData.success === false){
            AlertError(resposnseData.message);
            return
        }
        AlertSuccess(resposnseData.message)
        navigate("/orders");
    }).catch(e=>{
        AlertError(e);
    })
  };

  const addProduct = () => {
    let newAdded = [...productRows, { product_id: 0, quantity: 0,pack:0,unit_price:0}];
    setProductRows(newAdded);
   
  };
  const removeProduct = (index) => {
    let newRows = productRows.filter((arrow, key) => key !== index);
    setProductRows(newRows);
  };

  const updateProductRow = (res, index, type) => {
    if(type === 'product'){
      let updateProduct = [...productRows];
      updateProduct[index]["product_id"] = (res) ? res.value : 0
      updateProduct[index]["qauntity"] = (res) ? res.value : 0
      setProductRows(updateProduct);
    }
    if(type === 'quantity'){
      let updateQty = [...productRows];
      updateQty[index]["quantity"] = res.target.value;
       setProductRows(updateQty);
    }
    if(type === 'pack'){
      let updateQty = [...productRows];
      updateQty[index]["pack"] = res.target.value;
       setProductRows(updateQty);
    }  
    if(type === 'unit_price'){
      let updateUnitPrice = [...productRows];
      updateUnitPrice[index]["unit_price"] = res.target.value;
       setProductRows(updateUnitPrice);
    }  
  }
  const clientChangeHandler = (res) => {
    if(res){
        setSelectedClient(res)
        setClientId(res.value)
        getAddressData(res.value)
        getProductData(res.value);
    }else{
      setClientId(0);
      setSelectedClient(null)
      setAddressData([])
    }
  }
  /*Get Products Data*/
  const getProductData = (clientId) => {
    getData("/products/filter/"+clientId).then((response) => {
      if (response.status === 200) {
        let responseData = response.data;
        setProductData(responseData.data);
      }
    });
  };

  /*Get Products Data*/
  const getAddressData = (id) => {
    getData("/clients/addresses/filter/"+id).then((response) => {
      if (response.status === 200) {
        let responseData = response.data;
        setAddressData(responseData.data);
      }
    });
  };
  
                        
  useEffect(() => {
    const getClients = () => {
      getData("/clients/filter").then((response) => {
        if (response.status === 200) {
          let responseData = response.data;
          if (responseData.success) {
            setClientData(responseData.data);
          }
        }
      });
    };
    getClients();

      getData("/transportpartners").then((response) => {
        if (response.status === 200) {
          let responseData = response.data;
          if (responseData.success) {
            setCompanyData(responseData.data);
          }
        }
      });
      dispatch(setPageTitle('Create Sale Order'))
      dispatch(setBreadcrumbs([
        {
            label: 'Dashboard',
            link: '/dashboard',
            active: false, // true,false
            state: '/orders/add',
        },{
            label: 'Sale Orders',
            link: '/orders',
            active: false, // true,false
            state: '/orders/add',
        },{
          label: 'Create Sale Order',
          link: '/orders/add',
          active: true, // true,false
          state: '/orders/add',
      }
    ]))
  }, [dispatch]);

  const showAddressPopUp = () =>{
    if(!clientId){
      AlertError('Please select a client first.')
      return;
    }
    setShow(true)
  }
  const hideAddressPopUp = () =>{
      refreshAddress()
      setShow(false)
  }
  const refreshAddress = () => {
    getAddressData(clientId)
  }
  return (
    <DefaultLayout >
      <section className="section">
        <div className="row">
          <div className="card">
            <div className="card-body mt-5">
                          <div className="row mb-3">
                          <label htmlFor="client" className="col-3 col-form-label">Client<span className="text-danger">*</span></label>
                            <div className="col-4">
                                    <Select
                                        options={clientData}
                                        defaultValue={clientId}
                                        onChange={clientChangeHandler}
                                        isSearchable={true}
                                        id="client"
                                        isClearable={true}
                                        placeholder='Select a client'
                                      />
                                    </div>
                                   
                         </div>
                         <div className="row mb-3">
                          <label htmlFor="delivery_address" className="col-3 col-form-label">Delivery Address<span className="text-danger">*</span></label>
                            <div className="col-4">
                                    <Select
                                        options={addressData}
                                        defaultValue={addressId}
                                        onChange={(res)=> res ? setAddressId(res.value) : setAddressId(0)}
                                        isSearchable={true}
                                        id="delivery_address"
                                        isClearable={true}
                                        placeholder='Select a address'
                                      />
                                      <button onClick={showAddressPopUp} className="btn btn-primary btn-sm mt-2" title="Add New Address"><i className="bi bi-database-fill-add" /></button>
                                    </div>
                                   
                         </div>
                         <div className="row mb-3">
                          <label htmlFor="deliverydate" className="col-3 col-form-label">Delivery Date<span className="text-danger">*</span></label>
                            <div className="col-4">
                            <DatePicker
                                    className="form-control col-6"
                                    selected={deliveryDate}
                                    id="deliverydate"
                                    onChange={(date) => {
                                      setDeliveryDate(date)
                                    }}
                                    dateFormat="dd-MM-yyyy"
                                    placeholderText="Click to select a date"
                                    monthsShown={1}
                                    minDate={new Date()}
                                    isClearable={deliveryDate}
                                  />
                              </div>
                                   
                         </div>
                         <div className="row mb-3">
                          <label htmlFor="instructions" className="col-3 col-form-label">Delivery Instructions</label>
                            <div className="col-4">
                            <textarea
                                    className="form-control col-6"
                                    defaultValue={instructions}
                                    rows={10}
                                    cols={6}
                                    id="instructions"
                                    onInput={(res) => setInstructions(res.target.value)}
                                  ></textarea>
                              </div>
                                   
                         </div>
              <hr />
              <div className="col-12 mb-3">
                <h5 className="card-title">Products<span className="text-danger">*</span></h5>
              </div>
              {productRows.length > 0 &&
                productRows.map((product, index) => (
                  <div className="row g-3 mb-3" key={index}>
                    <div className="col-3">
                      <label className="col-form-label">Product<span className="text-danger">*</span></label>
                      <Select
                        options={productData}
                        defaultValue={product.product_id}
                        className="col-12"
                        isSearchable={true}
                        isClearable={true}
                        onChange={(res)=>updateProductRow(res, index,'product')}
                      />
                    </div>
                    <div className="col-2">
                      <label className="col-form-label">Pack/Size <span className="text-danger">*</span></label>
                      <input
                        type="number"
                        className="form-control"
                        min={1}
                        defaultValue={product.pack}
                        onInput={(res)=>updateProductRow(res, index,'pack')}
                      />
                     
                    </div>
                    <div className="col-2">
                      <label className="col-form-label">Quantity<span className="text-danger">*</span></label>
                      <input
                        type="number"
                        className="form-control"
                        min={1}
                        defaultValue={product.quantity}
                        onInput={(res)=>updateProductRow(res, index,'quantity')}
                      />
                    </div>
                    <div className="col-2">
                      <label className="col-form-label">Unit Price <span className="text-danger">*</span></label>
                      <input
                        type="number"
                        step='0.01'
                        pattern="[/^\d*\.?\d*$/]"
                        className="form-control"
                        defaultValue={product.price}
                        onInput={(res)=>updateProductRow(res, index,'unit_price')}
                      />
                    </div>
                    { productRows.length > 1 && 
                    <div className="col-2">
                      <div className="mt-2-5">
                        <DeleteButton
                          iclass="trash-fill"
                          buttonClass="danger"
                          clickfun={() => {if(window.confirm('Delete the item?')){ removeProduct(index) };}} 
                        />
                      </div>
                    </div>
                    }
                  </div>
                ))}
              <div className="row g-3 mb-3">
                <div className="col-3">
                  <PrimaryButton
                    title="Add Product"
                    clickfun={addProduct}
                    iclass="plus"
                    buttonClass="primary"
                  />
                </div>
              </div>
              <hr />
              <div className="col-12 mb-3">
                <h5 className="card-title">Transport Log<span className="text-danger">*</span></h5>
              </div>
              <div className="row g-3 mb-3">
                    <div className="col-3">
                      <label className="col-form-label">Company<span className="text-danger">*</span></label>
                      <Select
                        options={companyData}
                        onChange={(res)=>setCompanyObj(res)}
                        defaultValue={companyObj}
                        className="col-12"
                        isSearchable={true}
                        isClearable={true}
                      />
                    </div>
                    <div className="col-3">
                      <label className="col-form-label">Date & Time <span className="text-danger">*</span></label>
                      <DatePicker
                            className="form-control col-6"
                            selected={transportDate}
                            id="transport_date"
                            onChange={(date) => {
                              setTransportDate(date)
                            }}
                            dateFormat="dd-MM-yyyy HH:mm"
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            placeholderText="Click to select a date & time"
                            monthsShown={1}
                            showTimeSelect={true}
                            minDate={new Date()}
                            isClearable={true}
                          />
                     
                    </div>
                    <div className="col-3">
                      <label className="col-form-label">Trailer Number</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={trailer}
                        onInput={(res)=>setTrailer(res.target.value)}
                      />
                    </div>
                  </div>
              <div className="text-center">
                <button
                  type="button"
                  onClick={onSubmit}
                  className="btn btn-primary"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Addaddresspopup show={show} hideFn={hideAddressPopUp} showAddClient={false} clientData={clientData} selectedClient={selectedClient}    />
    </DefaultLayout>
  );
};
export default CreateSaleOrder;
