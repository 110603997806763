import React, { useEffect, useState,useRef } from "react";
import Table from "../../../components/Table";
import DefaultLayout from "../../../layouts/DefaultLayout";
import { getData } from "../../../services/HttpServices";
import { setPageTitle,setBreadcrumbs  } from '../../../store/resourceSlice';
import { useDispatch } from 'react-redux';
const CategoryList = () => {
    const searchRef = useRef("");
    const dispatch = useDispatch();

    const [categoryData,setCategoryData] = useState([])

    const [categoryCounts,setCategoryCounts] = useState(0)
    const [pageNo,setPageNo] = useState(1)
    const [isNext,setIsNext] = useState(false)
    const [limit,setLimit] = useState(10)
    const [search,setSearchData] = useState('');

    let tablColumns = {
        id:'ID',
        title:'EAN NO',
        status:'Status'
    }
    const getCategoryData = (pageNo, query='') =>{
        let url = '/category/list?page='+pageNo+'&query='+query
        getData(url).then((response)=>{
            if(response.status === 200){
                let responseData = response.data
                setCategoryData(responseData.data.categories)
                setCategoryCounts(responseData.data.counts)
                setPageNo(responseData.data.page_no)
                setIsNext(responseData.data.is_next)
                setLimit(responseData.data.limit)
            }
        })
    }
    const paginationUpdate = (pageno) => {
        setPageNo(pageno)
        getCategoryData(pageno,search)
    }
    useEffect(()=>{
        
        getCategoryData(pageNo)
    dispatch(setPageTitle('Categories'))
    dispatch(setBreadcrumbs([
        {
            label: 'Dashboard',
            link: '/dashboard',
            active: false, // true,false
            state: '/category',
        },{
            label: 'Categories',
            link: '/category',
            active: true, // true,false
            state: '/category',
        }
      ]))
     // eslint-disable-next-line
    },[dispatch])
    const onSearchChange = (data) => {
        setSearchData(data)
     };
      const submitFilter = () => {
        getCategoryData(1,search)
      }
      const clearFilter = () => {
        searchRef.current.value = ''
        getCategoryData(1)
        setSearchData("")
    }
    return(
        <DefaultLayout>
        <section className="section">
            <div className="row">
                <div className="card">
                    <div className="card-body mt-5">
                        <Table data={{'list':categoryData, 'column': tablColumns, counts:categoryCounts, pageno: pageNo,limit:limit, isNext:isNext, 'editlink': '/category/' }} updateFn = {paginationUpdate} submitFilter={submitFilter} clearfilter={clearFilter} 
                        filters={[         
                            {
                                label:"Search",
                                type:'search',
                                class:'form-control',
                                searchFilterFn:onSearchChange,
                                default:search,
                                ref:searchRef,
                            }
                        ]}
                        submenu={[
                                        {
                                            label:"Add New",
                                            link:'/category/add',
                                            buttonClass:'primary float-end'

                                        }
                                    ]}/> 
                        </div>
                        </div>
                        </div>
           </section>
        </DefaultLayout>
    )
}
export default CategoryList;