import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DefaultLayout from "../../../layouts/DefaultLayout";
import { getData,  putData } from "../../../services/HttpServices";
import { AlertError, AlertSuccess } from "../../../components/Alerts";
import { setPageTitle,setBreadcrumbs } from '../../../store/resourceSlice';
import { useDispatch } from 'react-redux';
import { PrimaryButton } from "../../../components/Button";
import { useReactToPrint } from 'react-to-print';
import { Invoice } from "./invoice";
import { DeliveryNote } from "../orders/deliverynote";
import Select from "react-select";
import DatePicker from "react-datepicker";
import moment from 'moment'
const SaleOrderView = () => {

    const componentRef = useRef();
    const deliveryRef = useRef();

    const dispatch = useDispatch();

    /*Fetch user id */
    const navigate = useNavigate();
    const { id } = useParams();
    /*Inital state data*/
    const [orderData, setOrderData] = useState();
    const [orderItems, setOrderItems] = useState([]);
    const [orderTransportLogs, setOrderTransportLogs] = useState();
    const [isUpdate, setIsUpdate] = useState(false);
    const [isUpdateTransport, setIsUpdateTransport] = useState(false);
    const [companyObj, setCompanyObj] = useState();
    const [companyData, setCompanyData] = useState([]);
    const [transportDate, setTransportDate] = useState();
    const [history, setHistory] = useState([]);
    /*Get order data*/
    const getOrderData = () => {
        getData("/orders/details/" + id).then((response) => {
            if (response.status === 200) {
                let responseData = response.data;
                if (responseData.success) {
                    setOrderData(responseData.data);
                    setOrderItems(responseData.data.items)
                    setOrderTransportLogs(responseData.data.transportlog)
                    setCompanyObj({value: responseData.data.transportlog.company_id,label: responseData.data.transportlog.company_name})
                    setTransportDate(moment(responseData.data.transportlog.transport_date).toDate())
                    setHistory(responseData.data.history);
                } else {
                    AlertError(responseData.message);
                    navigate("/orders");
                }
            }
        });
    };

    const rowUpdatehandler = (index, value, type) => {
        let items = [...orderItems];
        items[index][type] = value;
        setOrderItems(items)

    }
    const updateRowHandler = () => {
      let nextUpdate = !isUpdate
        if (!nextUpdate) {
            getOrderData();
        }
        setIsUpdate(nextUpdate)
    }
    const updateRowItemsHandler = () => {
        if (window.confirm("Are you sure wan't to update items?")) {
            putData("/orders/updateitems", orderData.items).then((response) => {
                if (response.status === 200) {
                    let responseData = response.data;
                    if (responseData.success) {
                        AlertSuccess(responseData.message)
                        setIsUpdate(false)
                        getOrderData()
                    }
                }
            });
        }
    }

    const updateTlogHandler = () => {
      let nextUpdate = !isUpdateTransport
      if (!nextUpdate) {
          getOrderData();
      }
      setIsUpdateTransport(nextUpdate)
    }
    const rowLoghandler = (value, type) => {
      const new_obj = { ...orderTransportLogs, [type]: value }
      setOrderTransportLogs(new_obj)

  }

      const updateTransportHandler = () => {
          if (window.confirm("Are you sure wan't to update info?")) {
            const orderTransportLog = { ...orderTransportLogs, transport_date: transportDate, company_id:companyObj.value,company_name:companyObj.label}
        
            putData("/transportlogs/update/"+id, orderTransportLog).then((response) => {
                  if (response.status === 200) {
                      let responseData = response.data;
                      if (responseData.success) {
                          AlertSuccess(responseData.message)
                          setIsUpdateTransport(false)
                          getOrderData()
                      }
                  }
              });
          }
      }

    useEffect(() => {
      getData("/transportpartners").then((response) => {
        if (response.status === 200) {
          let responseData = response.data;
          if (responseData.success) {
            setCompanyData(responseData.data);
          }
        }
      });
        getOrderData();
        dispatch(setPageTitle('Order Details'))
        dispatch(setBreadcrumbs([
          {
              label: 'Dashboard',
              link: '/dashboard',
              active: false, // true,false
              state: '/orders/details',
          },{
              label: 'Sale Orders',
              link: '/orders',
              active: false, // true,false
              state: '/orders/details',
          },{
            label: 'Order Details',
            link: '/orders/details/'+id,
            active: true, // true,false
            state: '/orders/details',
        }
      ]))
        // eslint-disable-next-line
    }, [id, navigate, dispatch]);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    const handleDeliveyNote = useReactToPrint({
      content: () => deliveryRef.current,
     });
    return (
        <DefaultLayout>
      <section className="section invoice-section" >
        <div className="row">
       
              <div className="card">
        <div className="card-body mt-3">
          <div className="col-12">
        <div className="order-detail-col mb-0">
          { orderData &&   
            <div className="grid invoice mt-3 mb-3" >
              <div className="grid-body">
                <div className="invoice-title">
                  <div className="row">
                    <div className="col-md-6 col-6">
                    <h4>Order:#{orderData.order_number}</h4>
                    </div>
                    <div className="col-md-6 col-6">
                    
                      <PrimaryButton clickfun={handlePrint} buttonClass="primary view-btn float-end no-print" buttonText="Print" iclass="file-earmark-pdf-fill" />
                      <PrimaryButton clickfun={handleDeliveyNote} buttonClass="primary view-btn float-end no-print" buttonText="Delivery Note" iclass="file-earmark-pdf-fill" />
                      <div className={'hide'}>
                     <Invoice ref={componentRef} orderData={orderData} orderItems={orderItems} />
                     <DeliveryNote ref={deliveryRef} orderData={orderData} orderItems={orderItems} />
                    </div>
                    </div>
                  </div>
                </div>
                <hr/>
                <div className="row">
              <div className="col-md-4 col-12 mb-2">
              
                <div className="billed-col">
                <h6 className="fw-bold">Customer:</h6>
                          <address>
                         
                            <b>Account Code: </b>{orderData.client_code} <br/>
                            {orderData.client_name} <br/>
                          </address>
                          </div>
                        </div>
                             
                  <div className="col-md-4 col-12 mb-2">
                 
                   <div className="billed-col">
                   <h6 className="fw-bold">Deliver To:</h6>
                    <address>
               
                      <b> {orderData.contact_name} </b><br/>
                      {orderData.address_1},{orderData.address_2}<br/>
                      {orderData.county} ,  {orderData.country}<br/>
                      {orderData.postcode}<br/>
                       <i className="bi bi-phone-vibrate"></i> {orderData.contact_number? orderData.contact_number : 'N/A'}<br/>
                       GLN Code:  {orderData.gln_code}<br/>
                    </address>
                  </div>  
                  </div>
               <div className="col-md-4 col-12 mb-2">
              
                      <div className="billed-col">
                      <h6 className="fw-bold">Order Information:</h6>
                    <address>
                      <span>Delivery Date: </span>{orderData.delivery_date}<br/>
                      <span>Status: </span> <span className={`fw-bold badge bg-${orderData.status_bgcolor}`}>{orderData.order_status}</span><br/>
                    </address>
                  </div>
                </div>
                  </div>
                <div className="row mt-3">
                  <div className="col-md-12 table-responsive">
                    <h5>ORDER SUMMARY</h5>
                    <table className="table table-striped">
                      <thead className="order-smr">
                        <tr>
                        <th scope="col"><strong>#</strong></th>
                        <th scope="col"><strong>Code</strong></th>
                        <th scope="col"><strong>Partner Code</strong></th>
                        <th scope="col"><strong>Description</strong></th>
                        <th scope="col"><strong>Barcode</strong></th>
                        <th scope="col"><strong>Quantity</strong></th>
                        <th scope="col"><strong>Pack</strong></th>
                        <th scope="col"><strong>Unit Price</strong></th>
                        {! isUpdate &&
                        <React.Fragment>
                          <th scope="col"><strong>Amount</strong></th>
                          <th scope="col"><strong>Line Total</strong></th>
                        </React.Fragment>
                        }
                      </tr>
                      </thead>
                      <tbody  className="order-sum">
                       {orderItems.length > 0 && orderItems.map((item, index) => (
                        <React.Fragment  key={index} >
                       { !isUpdate &&  <tr className={ item.item_status_id === 8 ? 'bg-danger text-white' :''}>
                            <th scope="row">{index + 1 }</th>
                            <td>{item.product_code}</td>
                            <td>{item.partner_code}</td>
                            <td>{item.product_description}
                            </td>
                            <td>{item.barcode}</td>
                            <td>{item.quantity }</td>
                            <td dangerouslySetInnerHTML={{ __html: item.pack }}></td>
                            <td>{(item.unit_price *1).toFixed(2) }</td>
                            <td>{(item.unit_price * item.quantity).toFixed(2)  }</td>
                            <td>{(item.unit_price * item.quantity).toFixed(2)  }</td>
                            
                        </tr> } 

                          { isUpdate && <tr>
                              <th scope="row">{index + 1 }</th>
                              <td>{item.product_code}</td>
                              <td>{item.partner_code}</td>
                              <td>{item.product_description}
                             
                              </td>
                              <td>{item.barcode}</td>
                              <td style={{width: "10%"}}><input type="number" min={1} className="form-control" defaultValue={item.quantity } onInput={(e)=> rowUpdatehandler(index, e.target.value, 'quantity')} />
                              </td>
                              <td style={{width: "10%"}}><input type="number" min={1} className="form-control" defaultValue={item.pack } onInput={(e)=> rowUpdatehandler(index, e.target.value, 'pack')} /></td>

                              <td style={{width: "10%"}}><input type="number" min={1} className="form-control" defaultValue={item.unit_price } onInput={(e)=> rowUpdatehandler(index, e.target.value, 'unit_price')} /></td>
                            </tr>
                            }
                            </React.Fragment>
                      ))}
                      {orderData.status_id === 1 && 
                      <tr>
                          <td className="text-center" colSpan="11">
                          {isUpdate &&
                           <React.Fragment>
                           <PrimaryButton buttonText='Return' buttonClass='warning float-end' title='Cancel' clickfun={()=> updateRowHandler() } iclass='arrow-counterclockwise' /> 
                            <PrimaryButton  buttonText='Update Items' buttonClass='danger float-end' title='Update Items' clickfun={()=> updateRowItemsHandler() } iclass='database' /> 
                           </React.Fragment>
                          }
                          {!isUpdate &&
                          <PrimaryButton buttonText='Edit Items' buttonClass='success float-end' title='Edit Items' clickfun={()=> updateRowHandler() } iclass='pencil-fill' /> }
                          </td>
                        </tr>
                        
                      } 
                      </tbody>
                    </table>
                  </div>            
                </div>
                <div className="row mt-3">
                  <div className="col-md-12 table-responsive">
                      <h5>TRANSPORT INFORMARTION</h5>
                      <table className="table table-striped">
                      <thead className="order-smr">
                        <tr>
                        <th scope="col"><strong>Company Name</strong></th>
                        <th scope="col"><strong>Date</strong></th>
                        { !isUpdateTransport &&<th scope="col"><strong>Time</strong></th>}
                        <th scope="col"><strong>Trailer Number</strong></th>
                        <th scope="col"><strong>Pallets</strong></th>
                        <th scope="col"><strong>Collections</strong></th>
                      </tr>
                      </thead>
                      <tbody  className="order-sum">
                      { !isUpdateTransport && <tr>
                            <td>{orderTransportLogs.company_name}</td>
                            <td>{orderTransportLogs.date}</td>
                            <td>{orderTransportLogs.time}</td>
                            <td>{orderTransportLogs.trailer_number || 'N/A'}</td>
                            <td>{orderTransportLogs.pallets || 'N/A'}</td>
                            <td>{orderTransportLogs.collections || 'N/A'}</td>
                            
                        </tr>
                        }
                      
                        { isUpdateTransport && <tr>
                            <td style={{ width:"20%" }}>
                            <Select
                              options={companyData}
                              onChange={(res)=> setCompanyObj(res)}
                              defaultValue={companyObj}
                              className="col-12"
                              isSearchable={true}
                              isClearable={true}
                            />
                            </td>
                            <td>
                            <DatePicker
                            className="form-control col-6"
                            selected={transportDate}
                            id="transport_date"
                            onChange={(date) => {
                              setTransportDate(date)
                            }}
                            dateFormat="dd-MM-yyyy HH:mm"
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            placeholderText="Click to select a date & time"
                            monthsShown={1}
                            showTimeSelect={true}
                            isClearable={true}
                          /></td>
                            <td><input type="text" className="form-control" defaultValue={orderTransportLogs.trailer_number } onInput={(e)=> rowLoghandler(e.target.value, 'trailer_number')} /></td>
                            <td><input type="text" className="form-control" defaultValue={orderTransportLogs.pallets } onInput={(e)=> rowLoghandler(e.target.value, 'pallets')} /></td>
                            <td><input type="text" className="form-control" defaultValue={orderTransportLogs.collections } onInput={(e)=> rowLoghandler(e.target.value, 'collections')} /></td>
                            
                        </tr>
                        }
                        <tr>
                          <td className="text-center" colSpan="6">
                          {isUpdateTransport &&
                           <React.Fragment>
                           <PrimaryButton buttonText='Return' buttonClass='warning float-end' title='Cancel' clickfun={()=> updateTlogHandler() } iclass='arrow-counterclockwise' /> 
                            <PrimaryButton  buttonText='Update Log' buttonClass='danger float-end' title='Update Log' clickfun={()=> updateTransportHandler() } iclass='database' /> 
                           </React.Fragment>
                          }
                          {!isUpdateTransport &&
                          <PrimaryButton buttonText='Edit Transport Log' buttonClass='success float-end' title='Edit Transport Log' clickfun={()=> updateTlogHandler() } iclass='pencil-fill' /> }
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    </div>
                    </div>
                    {history.length > 0 &&
                    <div className="row">
                      <div className="col-xl-12">
                        <h5 className="card-title">Order History Detail</h5>
                        <ul className="timeline">
                        
                            { history.map((his, index) => ( <li className="timeline-item mb-5" key={index}>
                            <h6>{his.heading}</h6>
                            <small className="mb-5">{his.created}</small>
                            <div className="col-xl-12" dangerouslySetInnerHTML={{ __html: his.description }}></div>
                          </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                     }
                  </div>
                </div>   
       
         
          }
        </div>
  </div>
</div>        
</div>

        </div>
      </section>
    </DefaultLayout>
    );
};
export default SaleOrderView;