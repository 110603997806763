import React, { useEffect, useState } from "react"
import DefaultLayout from "../../../layouts/DefaultLayout"
import { useNavigate, useParams } from "react-router-dom"
import { getData, putData } from "../../../services/HttpServices"
import { AlertError, AlertSuccess } from "../../../components/Alerts"
import { useForm } from 'react-hook-form';
import Skeleton from "react-loading-skeleton"
import { setPageTitle,setBreadcrumbs } from '../../../store/resourceSlice';
import { useDispatch } from 'react-redux';
import Select from "react-select";
const EditClient = () => {
    const dispatch = useDispatch();
    /*Fetch user id */
    const navigate = useNavigate()
    const { id } = useParams()


    /*Inital state data*/
    const [ClientData, setClientData] = useState([]);
    const [clientsData, setClientsData] = useState([]);
    const [selectClientId, setSelectClientId] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    /*Update User Data*/
    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = data => {
        data.parent_id = selectClientId?selectClientId.value:0;
        putData('/clients/update/' + id, data).then((response) => {
            let resposnseData = response.data
            if (resposnseData.success === false) {
                AlertError(resposnseData.message);
                return
            }
            AlertSuccess(resposnseData.message)
            navigate("/clients");
        }).catch(e => {
            AlertError(e);
        })
    }
    useEffect(() => {

        
        /*Get Client data*/

        const getClientData = () => {
            getData('/clients/details/' + id).then((response) => {
                if (response.status === 200) {
                    let responseData = response.data
                    if (responseData.success) {
                        setClientData(responseData.data)
                        setSelectClientId(responseData.data.parents)

                    } else {
                        AlertError(responseData.message)
                        navigate('/clients')
                    }
                    setIsLoading(true)
                }
            })
        }
        
        getData("/clients/parent/filter").then((response) => {
            if (response.status === 200) {
                let responseData = response.data;
                if (responseData.success) {
                    setClientsData(responseData.data);
                }
            }
            getClientData()
        });
        dispatch(setPageTitle('Edit Client'))
        dispatch(setBreadcrumbs([
            {
                label: 'Dashboard',
                link: '/dashboard',
                active: false, // true,false
                state: '/clients/edit',
            },{
                label: 'Clients Address',
                link: '/clients/addresslist',
                active: false, // true,false
                state: '/clients/edit',
            },{
                label: 'Clients',
                link: '/clients',
                active: false, // true,false
                state: '/clients/edit',
            },{
                label: 'Edit Client',
                link: '/clients/edit/'+id,
                active: true, // true,false
                state: '/clients/edit',
            }
        ]))
    }, [id, navigate, dispatch])
    return (
        <DefaultLayout>
        <section className="section">
            <div className="row">
                <div className="card">
                <div className="card-body">
                            <div className="container py-5 h-100">
<div className="row d-flex justify-content-center align-items-center h-100">

<div className="col-lg-12 col-xl-12">
                        {isLoading ?
                        <form onSubmit={handleSubmit(onSubmit)}>
                           <div className="row">
                               <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="code" className="col-sm-12 col-form-label">Code<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="code" defaultValue={ClientData.code}  {...register("code", {required: true , maxLength: 20})}/>
                                        {errors?.code && errors.code.type === "required" && <span className="error">Please enter code</span>}
                                        {errors?.code && errors.code.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                   
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="name" className="col-sm-12 col-form-label">Name<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="name" defaultValue={ClientData.name}  {...register("name", {required: true , maxLength: 128})}/>
                                        {errors?.name && errors.name.type === "required" && <span className="error">Please enter name</span>}
                                        {errors?.name && errors.name.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                   
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="email" className="col-sm-12 col-form-label">Email</label>
                                    <div className="col-sm-12">
                                        <input type="email" className="form-control" id="email" defaultValue={ClientData.email} {...register("email", {required: false, pattern: {
                                            value: /\S+@\S+\.\S+/,
                                            message: "Entered value does not match email format",
                                        }})} />
                                        {errors?.email && errors.email.type === "required" && <span className="error">Please enter email</span>}
                                        {errors?.email && errors.email.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                    
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="phone" className="col-sm-12 col-form-label">Phone</label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="phone" defaultValue={ClientData.phone} {...register("phone", {required: false , minLength: 10, maxLength: 20})} />
                                        {errors?.phone && errors.phone.type === "required" && <span className="error">Please enter phone</span>}
                                        {errors?.phone && errors.phone.type === "maxLength" && <span className="error">Phone no should not be exceeded 20 digits </span> }
                                        {errors?.phone && errors.phone.type === "minLength" && <span className="error">Phone no should be min 10 digits</span> }
                                    </div>
                                    
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="contactname" className="col-sm-12 col-form-label">Contact Name<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="contactname" defaultValue={ClientData.contact_name} {...register("contactname", {required: true , maxLength: 50})} />
                                        {errors?.contactname && errors.contactname.type === "required" && <span className="error">Please enter contact name</span>}
                                        {errors?.contactname && errors.contactname.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                    
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="contactnumber" className="col-sm-12 col-form-label">Contact Number<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="contactnumber" defaultValue={ClientData.contact_number} {...register("contactnumber", {required: true , maxLength: 20})} />
                                        {errors?.contactnumber && errors.contactnumber.type === "required" && <span className="error">Please enter contact number</span>}
                                        {errors?.contactnumber && errors.contactnumber.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                    
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="address_1" className="col-sm-12 col-form-label">Address 1<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="address1" defaultValue={ClientData.address_1} {...register("address_1", {required: true , maxLength: 30})} />
                                        {errors?.address_1 && errors.address_1.type === "required" && <span className="error">Please enter address 1</span>}
                                        {errors?.address_1 && errors.address_1.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                    
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="address_1" className="col-sm-12 col-form-label">Address 2</label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="address_2" defaultValue={ClientData.address_2} {...register("address_2", {required: false , maxLength: 30})} />
                                        {errors?.address_2 && errors.address_2.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                    
                                </div>

                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="city" className="col-sm-12 col-form-label">City<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="city" defaultValue={ClientData.city} {...register("city", {required: true , maxLength: 20})} />
                                        {errors?.city && errors.city.type === "required" && <span className="error">Please enter city</span>}
                                        {errors?.city && errors.city.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="county" className="col-sm-12 col-form-label">County<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="county" defaultValue={ClientData.county} {...register("county", {required: true , maxLength: 20})} />
                                        {errors?.county && errors.county.type === "required" && <span className="error">Please enter county</span>}
                                        {errors?.county && errors.county.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="postcode" className="col-sm-12 col-form-label">Post Code</label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="postcode" defaultValue={ClientData.postcode} {...register("postcode", {required: false , maxLength: 10})} />
                                        {errors?.postcode && errors.postcode.type === "required" && <span className="error">Please enter post code</span>}
                                        {errors?.postcode && errors.postcode.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="vat_number" className="col-sm-12 col-form-label">VAT Number</label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="vat_number" defaultValue={ClientData.vat_number} {...register("vat_number", {required: false })} />
                                        {errors?.vat_number && errors.vat_number.type === "required" && <span className="error">Please enter VAT number</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="status" className="col-sm-12 col-form-label">Parent<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                    <Select
                                        options={clientsData}
                                        value={selectClientId}
                                        onChange={(res)=> setSelectClientId(res)}
                                        isSearchable={true}
                                        id="clientparent"
                                        isClearable={true}
                                        placeholder='Select a parent'
                                      />
                                    </div>
                                   
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="status" className="col-sm-12 col-form-label">Status<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <select id="status" className="form-control" defaultValue={ClientData.status} {...register("status", {required: true})}>
                                            <option value={1}>Active</option>
                                            <option value={2}>In-Active</option>
                                        </select>
                                        {errors?.status && <span className="error">Please select a status</span>}
                                    </div>
                                   
                                </div>
                                <div className="text-center">
                                    <button type="submit" className="btn btn-primary sub-btn">Submit</button>
                                </div>
                                </div>
                            </form>
:<Skeleton style={{
    width: '100%',
    marginBottom:5,
    marginTop:5,
    height:20
  }} count={10} inline={true} />}
                        </div>
                        </div>
                        </div>
                            </div>
                        </div>
                        </div>
           </section>
        </DefaultLayout>
    )
}
export default EditClient;