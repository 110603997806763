
import { getData } from '../services/HttpServices';
import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
const classicMineralWaterSlice = createSlice({
    name: 'classicMineralWater',
    initialState: {
        profile: [],
        pageTitle:'',
        breadcrumbs:[],
        filters: {}
    },
    reducers: {
        setFilters(state, action) {
            state.filters = action.payload
        },
        setPageTitle(state, action) {
            document.title = 'Classic Mineral Water | '+action.payload
            state.pageTitle = action.payload
        },
        setBreadcrumbs(state, action) {
            state.breadcrumbs = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCurrentLogin.pending, (state, action) => {
                //console.log('Pending Thunk')
            })
            .addCase(fetchCurrentLogin.fulfilled, (state, action) => {
                state.profile = action.payload
            })
            .addCase(fetchCurrentLogin.rejected, (state, action) => {
               // console.log('Rejected Thunk')
            })
    }
})

export const fetchCurrentLogin = createAsyncThunk('resources/fetch', async (state, action) => {
    try {
       return getData('/currentloggedin').then((response)=>{
      
        let resposnseData = response.data
        if(resposnseData.success){
            return resposnseData.data
        }
        })
    } catch (err) {
        //console.log(err)
    }
})

export const { setPageTitle, setFilters,setBreadcrumbs} = classicMineralWaterSlice.actions;
export default classicMineralWaterSlice.reducer;