import React, { useEffect, useState } from "react"
import DefaultLayout from "../../layouts/DefaultLayout"
import { useNavigate, useParams } from "react-router-dom"
import { getData, putData } from "../../services/HttpServices"
import { AlertError, AlertSuccess } from "../../components/Alerts"
import { useForm } from 'react-hook-form';
import Skeleton from "react-loading-skeleton"
import { setPageTitle,setBreadcrumbs } from '../../store/resourceSlice';
import { useDispatch } from 'react-redux';
const EditTaxCode = ({ props }) => {
    const dispatch = useDispatch();
    /*Fetch tax code id */
    const navigate = useNavigate()
    const { id } = useParams()


    /*Inital state data*/
    const [taxCodeData, setTaxCodeData] = useState(null);

    /*Update User Data*/
    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = data => {
        putData('/taxcodes/update/' + id, data).then((response) => {
            let resposnseData = response.data
            if (resposnseData.success === false) {
                AlertError(resposnseData.message);
                return
            }
            AlertSuccess(resposnseData.message)
            navigate('/taxcodes')
        }).catch(e => {
            AlertError(e);
        })
    }


    useEffect(() => {
        /*Get tax code data*/
        const getTaxCodeData = () => {
            getData('/taxcodes/details/' + id).then((response) => {
                if (response.status === 200) {
                    let responseData = response.data
                    if (responseData.success) {
                        setTaxCodeData(responseData.data)
                    } else {
                        AlertError(responseData.message)
                        navigate('/taxcodes')
                    }
                }
            })
        }
        getTaxCodeData()

        dispatch(setPageTitle('Edit Supplier Tax Codes'))
        dispatch(setBreadcrumbs([
            {
                label: 'Dashboard',
                link: '/dashboard',
                active: false, // true,false
                state: '/taxcodes/edit',
            },{
                label: 'Supplier Tax Codes',
                link: '/taxcodes',
                active: false, // true,false
                state: '/taxcodes/edit/',
            },{
                label: 'Edit Supplier Tax Codes',
                link: '/taxcodes/edit/'+id,
                active: true, // true,false
                state: '/taxcodes/edit',
            }
        ]))
    }, [id, navigate, dispatch])
    return (
        <DefaultLayout>
        <section className="section">
            <div className="row">
                <div className="card">
                      <div className="card-body">
                                       <div className="container py-5 h-100">
                    <div className="row d-flex justify-content-center align-items-center h-100">

<div className="col-lg-12 col-xl-12">
                        {taxCodeData ?
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <div className="row">
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="name" className="col-sm-12 col-form-label">Code<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="code" defaultValue={taxCodeData.code}  {...register("code", {required: true , maxLength: 20})}/>
                                        {errors?.code && errors.code.type === "required" && <span className="error">Please enter code</span>}
                                        {errors?.code && errors.code.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                   
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="phone" className="col-sm-12 col-form-label">Vat Rate<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="vatrate" defaultValue={taxCodeData.vat_rate} {...register("vatrate", {required: true , maxLength: 20})} />
                                        {errors?.vatrate && errors.vatrate.type === "required" && <span className="error">Please enter vat rate</span>}
                                        {errors?.vatrate && errors.vatrate.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                    
                                </div>
                                
                                <div className="text-center">
                                    <button type="submit" className="btn btn-primary sub-btn">Submit</button>
                                </div>
                                 </div>
                            </form>
:<Skeleton style={{
    width: '100%',
    marginBottom:5,
    marginTop:5,
    height:20
  }} count={10} inline={true} />}
                        </div>
                        </div>
                        </div>
                            </div>
                        </div>
                        </div>
           </section>
        </DefaultLayout>
    )
}
export default EditTaxCode;