import React, { useEffect, useState } from "react";
import Table from "../../components/Table";
import DefaultLayout from "../../layouts/DefaultLayout";
import { getData } from "../../services/HttpServices";
import { setPageTitle,setBreadcrumbs  } from '../../store/resourceSlice';
import { useDispatch } from 'react-redux';
const URL = process.env.REACT_APP_API_URL
const ProductionList = () => {
    const dispatch = useDispatch();
    const [ProductionData,setProductionData] = useState([]);
    const [dataCounts,setDataCounts] = useState(0)
    const [pageNo,setPageNo] = useState(1)
    const [isNext,setIsNext] = useState(false)
    const [limit,setLimit] = useState(10)
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    
    let tableColumns = {
        id:'ID',
        title:'Title',
        production_date:'Date',
        bottle_total:"Total Bottles",
        pallets_total:"Total Pallets"
    }
    const getProductionData = (pageNo,date) =>{
        let url = '/productionplans?page='+pageNo;
        if(date){
            url +='&start='+date.start+'&end='+date.end
        }
        getData(url).then((response)=>{
            if(response.status === 200){
                let responseData = response.data
                setProductionData(responseData.data.plans)
                setDataCounts(responseData.data.counts)
                setPageNo(responseData.data.page_no)
                setIsNext(responseData.data.is_next)
                setLimit(responseData.data.limit)
            }
        })
        
    }
    const paginationUpdate = (pageno) => {
            let date = {
                'start': (startDate) ?  startDate.toLocaleDateString("en-UK") : "",
                'end': (endDate) ? endDate.toLocaleDateString("en-UK") : "",
            }
            setPageNo(pageno)
            getProductionData(pageno,date)
    }
    const submitFilter = () => {
        let date = {
            'start': (startDate) ?  startDate.toLocaleDateString("en-UK") : "",
            'end': (endDate) ? endDate.toLocaleDateString("en-UK") : "",
         }
         getProductionData(1,date)
    }
    const clearFilter = () => {
        setStartDate(null);
        setEndDate(null);
        getProductionData(1)
    }
    const onDateRangeChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };
    const handleExport = (id) => {
        getData("/productionplans/export/" + id).then(response => {
          if (response.status === 200) {
            let responseData = response.data;
            window.open(URL+"/productionplans/download/"+responseData.data.file, '_blank');
          }
            
        })
      }
    useEffect(()=>{
       
        dispatch(setPageTitle('Production Plans'))
        getProductionData(1)
        // eslint-disable-next-line
        dispatch(setBreadcrumbs([
            {
                label: 'Dashboard',
                link: '/dashboard',
                active: false, // true,false
                state: '/productionplans',
            },{
                label: 'Production Plans',
                link: '/productionplans',
                active: true, // true,false
                state: '/productionplans',
            }
          ]))
    },[dispatch])
    return(
        <DefaultLayout>
             <section className="section">
            <div className="row">
                <div className="card">
                    <div className="card-body mt-5">
                      
                        <Table data={{'list':ProductionData, 'column': tableColumns, counts:dataCounts, pageno: pageNo,limit:limit, isNext:isNext, 'viewLink': '/productionplans/view/' }} updateFn = {paginationUpdate} submitFilter={submitFilter} clearfilter={clearFilter} exportFun={handleExport}
                        filters={[   {
                                label:"Date Range",
                                type:'daterange',
                                class:'form-control',
                                startDate:startDate,
                                endDate:endDate,
                                placeholderText:'Select Date Range',
                                dateRangeFn:onDateRangeChange
                            }
                        ]}
                        submenu={[ {
                                            label:"Add New",
                                            link:'/productionplans/create',
                                            buttonClass:'primary float-end '

                                        }
                                    ]}
                                    /> 
                        </div>
                        </div>
                        </div>
           </section>
           
        </DefaultLayout>

   )
}
export default ProductionList;