import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DefaultLayout from "../../layouts/DefaultLayout";
import { getData } from "../../services/HttpServices";
import {
  AlertError
} from "../../components/Alerts";
import { PrimaryButton } from "../../components/Button";
import { setPageTitle,setBreadcrumbs } from "../../store/resourceSlice";
import { useDispatch } from "react-redux";
const URL = process.env.REACT_APP_API_URL
const ProductionPlanView = () => {
  const dispatch = useDispatch();

  /*Fetch user id */
  const navigate = useNavigate();
  const { id } = useParams();
  /*Inital state data*/
  const [planData, setPlanData] = useState(null);
  const [planItems, setPlanItems] = useState([]);
  const [history, setHistory] = useState([]);
  /*Get order data*/
  const getOrderData = () => {
    getData("/productionplans/details/" + id).then((response) => {
      if (response.status === 200) {
        let responseData = response.data;
        if (responseData.success) {
            setPlanData(responseData.data);
            setPlanItems(responseData.data.products);
            setHistory(responseData.data.history);
        } else {
          AlertError(responseData.message);
          navigate("/productionplans");
        }
      }
    });
  };
  
  const handleExport = () => {
    getData("/productionplans/export/" + id).then(response => {
      if (response.status === 200) {
        let responseData = response.data;
        window.open(URL+"/productionplans/download/"+responseData.data.file, '_blank');
      }
        
    })
  }
 
  useEffect(() => {
    getOrderData();
    dispatch(setPageTitle("Production Plan Details"));
    dispatch(setBreadcrumbs([
      {
          label: 'Dashboard',
          link: '/dashboard',
          active: false, // true,false
          state: '/productionplans/view',
      },{
          label: 'Production Plans',
          link: '/productionplans',
          active: false, // true,false
          state: '/productionplans/view',
      },{
        label: 'Production Plan Details',
        link: '/productionplans/view/'+id,
        active: true, // true,false
        state: '/productionplans/view',
    }
    ]))
    // eslint-disable-next-line
  }, [id, navigate, dispatch]);

  return (
    <DefaultLayout> 
      <section className="section">
        <div className="row">
          <div className="card">
             <div className="card-body">
              {planData && (
                <div className="container mb-5 mt-3">
                <div className="row d-flex align-items-baseline">
                <div className="col-xl-12">
                      <PrimaryButton clickfun={handleExport} buttonClass="primary view-btn float-end" buttonText="Export" iclass="file-excel-fill" />
                      
                  </div>
                  </div>
                  <div className="row d-flex align-items-baseline">
                  <div className="col-xl-6">
                      <h6 className="float-start">
                      <p>Classic Mineral Water Company Limited</p>
                      <p>Production Plan</p>
                      <p >{planData.same_date && ( 'Date: '+planData.start_date) }</p>
                      <p >{!planData.same_date && ( 'Start Date: '+planData.start_date) }</p>
                      <p >{!planData.same_date && ( 'End Date: '+planData.end_date) }</p>
                      </h6>
                    </div>
                    
                  </div>
                  <hr />
                  <div className="container">
                    <div className="row my-2 mx-1 justify-content-center table-responsive">
                    <table className="table table-striped">
                                    <thead>
                                           <tr className="table-primary">
                                            <th style={{ textAlign:"center", }} scope="col" colSpan="13">{planData.title}</th>
                                        </tr>
                                        <tr className="table-primary">
                                        <th>Product</th>
                                        <th>DRS</th>
                                        <th>Spec File Page no</th>
                                        <th>Spec Ref</th>
                                        <th>Pallets</th>
                                        <th>Bottles</th>
                                        <th>Litre Per Pallets</th>
                                        <th>Syrup</th>
                                        <th>Caps</th>
                                        <th>Actual Bottle</th>
                                        <th>Actual Syrup</th>
                                        <th>Barcode & Pallet Sheet Colour</th>
                                        <th>ADDITIONAL PALLET SHEETS- 4 PER PALLET</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {planItems.length > 0 &&
                            planItems.map((item, index) => (
                                  <tr key={index}>
                                     <td>{item.description}</td>
                                    <td>{item.drs}</td>
                                    <td>{item.fileno}</td>
                                    <td>{item.Ref}</td>
                                    <td>{item.pallets}</td>
                                    <td>{item.bottles}</td>
                                    <td>{item.litresperpallets}</td>
                                    <td>{item.syrup}</td>
                                    <td>{item.caps}</td>
                                    <td>{item.actual_bottle}</td>
                                    <td>{item.actual_syrup}</td>
                                    <td>{item.barcode_pallet_sheet_colour}</td>
                                    <td>{item.additonal_pallet_sheets}</td>
                                  </tr>
                            ))}
                                    </tbody>
                                </table>
                    </div>
                    {history.length > 0 &&
                    <div className="row my-2 mx-1 justify-content-center">
                      <div className="col-xl-12">
                        <h5 className="card-title">Production History Detail</h5>
                        <ul className="timeline">
                        
                            { history.map((his, index) => ( <li className="timeline-item mb-5" key={index}>
                            <h6>{his.heading}</h6>
                            <small className="mb-5">{his.created}</small>
                            <div className="col-xl-12" dangerouslySetInnerHTML={{ __html: his.description }}></div>
                          </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                     }
                  </div>
               
                </div>
              )}
            </div> 
          </div>
        </div>
      </section>
    </DefaultLayout>
  );
};
export default ProductionPlanView;
