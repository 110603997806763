import React, { useEffect, useState,useRef } from "react";
import Table from "../../../components/Table";
import DefaultLayout from "../../../layouts/DefaultLayout";
import { getData } from "../../../services/HttpServices";
import { setPageTitle,setBreadcrumbs  } from '../../../store/resourceSlice';
import { useDispatch } from 'react-redux';
const TransportLogs = () => {
    const searchRef = useRef("");
    const dispatch = useDispatch();
    const [orderData,setOrderData] = useState([])
    const [orderCounts,setOrderCounts] = useState(0)
    const [pageNo,setPageNo] = useState(1)
    const [isNext,setIsNext] = useState(false)
    const [limit,setLimit] = useState(10)
    const [search,setSearchData] = useState('')
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [parnetsData,setParnetsData] = useState([])
    const [selectedParnter,setSelectedPartner] = useState(0)
    
    let tablColumns = {
        id:'ID',
        order_number:'Order Number',
        company_name:'Company Name',
        trailer_number:'Trailer Number',
        date: 'Date',
        time: 'Time',
    }
    const getOrderData = (pageNo, query='',date='',parnter='') =>{

        let url = '/transportlogs/list?page='+pageNo+'&query='+query+'&parnter='+parnter;
        if(date){
            url +='&start='+date.start+'&end='+date.end
        }
        
        getData(url).then((response)=>{
            if(response.status === 200){
                let responseData = response.data
                setOrderData(responseData.data.logs)
                setOrderCounts(responseData.data.counts)
                setPageNo(responseData.data.page_no)
                setIsNext(responseData.data.is_next)
                setLimit(responseData.data.limit)
            }
        })
    }
    const paginationUpdate = (pageno) => {
        setPageNo(pageno)
        let date = {
            'start': (startDate) ?  startDate.toLocaleDateString("en-UK") : "",
            'end': (endDate) ? endDate.toLocaleDateString("en-UK") : "",
         }
         let parnter = ''
         if(selectedParnter)
            parnter = selectedParnter.value

        getOrderData(pageno,search,date,parnter)
    }
    const filterResult = () =>{
        let date = {
            'start': (startDate) ?  startDate.toLocaleDateString("en-UK") : "",
            'end': (endDate) ? endDate.toLocaleDateString("en-UK") : "",
         }
         let parnter = ''
         if(selectedParnter)
            parnter = selectedParnter.value
 
         getOrderData(1,search,date,parnter)
    }
    const clearFilter = () => {
        setSearchData("")
        getOrderData(1,"")
        setSelectedPartner(null)
        searchRef.current.value = ''
    }
    const onSearchChange = (data) => {
        setSearchData(data)
     };
    
     const onDateRangeChange = (dates) => {
         const [start, end] = dates;
         setStartDate(start);
         setEndDate(end);
     };
     const ChangePartnerHandler = (res) => {
        if(res)
         setSelectedPartner(res)
        else
        setSelectedPartner(null)
     };

    useEffect(()=>{
            getData('/transportpartners').then((response)=>{
                if(response.status === 200){
                    let responseData = response.data
                    setParnetsData(responseData.data)
                }
            })
 dispatch(setPageTitle('Transport Logs'))
  setTimeout(()=>{
    let date = {
        'start': (startDate) ?  startDate.toLocaleDateString("en-UK") : "",
        'end': (endDate) ? endDate.toLocaleDateString("en-UK") : "",
     }
     let parnter = ''
     if(selectedParnter)
        parnter = selectedParnter.value
        getOrderData(pageNo, search, date,parnter)
    },500)
    dispatch(setBreadcrumbs([
        {
            label: 'Dashboard',
            link: '/dashboard',
            active: false, // true,false
            state: '/transport-logs',
        },{
            label: 'Transport Logs',
            link: '/transport-logs',
            active: true, // true,false
            state: '/transport-logs',
        }
    ]))
    // eslint-disable-next-line
    },[dispatch])

    return(
        <DefaultLayout>
        <section className="section">
            <div className="row">
                <div className="card">
                    <div className="card-body mt-5">
                        <Table data={{'list':orderData, 'column': tablColumns, counts:orderCounts, pageno: pageNo,limit:limit, isNext:isNext}} updateFn = {paginationUpdate} 
                        submitFilter={filterResult}
                        clearfilter={clearFilter}
                    
                        filters={[         
                            {
                                label:"Find by Order Number",
                                type:'search',
                                class:'form-control',
                                default:search,
                                ref:searchRef,
                                searchFilterFn:onSearchChange
                            },{
                                label:"Date Range",
                                type:'daterange',
                                class:'form-control',
                                startDate:startDate,
                                endDate:endDate,
                                placeholderText:'Select Date Range',
                                dateRangeFn:onDateRangeChange
                            },
                            {
                                label:"Select Partner",
                                type:"select",
                                class:'form-control',
                                options:parnetsData,
                                default:selectedParnter,
                                changeOptionsFn:ChangePartnerHandler,
                                isClearable:true
                            }
                        ]}
                        /> 
                        </div>
                        </div>
                        </div>
           </section>
        </DefaultLayout>
    )
}
export default TransportLogs;