import React, { useEffect } from "react";
import DefaultLayout from "../../../layouts/DefaultLayout";
import { useNavigate } from "react-router-dom";
import { postData } from "../../../services/HttpServices";
import { AlertError, AlertSuccess } from "../../../components/Alerts";
import { useForm } from "react-hook-form";
import { setPageTitle,setBreadcrumbs } from "../../../store/resourceSlice";
import { useDispatch } from "react-redux";
const AddCategory = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  /*Add category Data*/
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const onSubmit = (data) => {
    postData("/category/add", data)
      .then((response) => {
        let resposnseData = response.data;
        if (resposnseData.success === false) {
          AlertError(resposnseData.message);
          return;
        }
        AlertSuccess(resposnseData.message);
        navigate("/category");
      })
      .catch((e) => {
        AlertError(e);
      });
  };

  useEffect(() => {
    dispatch(setPageTitle("Add Category"));
    dispatch(setBreadcrumbs([
      {
          label: 'Dashboard',
          link: '/dashboard',
          active: false, // true,false
          state: '/category/add',
      },{
          label: 'Categories',
          link: '/category',
          active: false, // true,false
          state: '/category/add',
      },{
        label: 'Add Category',
        link: '/category/add',
        active: true, // true,false
        state: '/category/add',
    }
    ]))
  }, [dispatch]);
  
  return (
    <DefaultLayout>
      <section className="section">
        <div className="row">
          <div className="card">
            <div className="card-body">
              <div className="container py-5 h-100">
                <div className="row d-flex justify-content-center align-items-center h-100">
                  <div className="col-lg-12 col-xl-12">
                    <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="title" className="col-sm-12 col-form-label">Title<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="title" defaultValue={""} {...register("title", {required: true , maxLength: 50})} />
                                        {errors?.title && errors?.title.type === "required" && <span className="error">Please enter ean no</span>}
                                        {errors?.title && errors?.title.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                   
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="status" className="col-sm-12 col-form-label">Status <span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <select id="status" className="form-control" defaultValue={1} {...register("status", {required: true})}>
                                            <option value={1}>Active</option>
                                            <option value={2}>In-Active</option>
                                        </select>
                                        {errors?.status && <span className="error">Please select a status</span>}
                                    </div>
                                   
                                </div>
                                <div className="text-center">
                                    <button type="submit" className="btn btn-primary sub-btn">Submit</button>
                                </div>
                                </div> 
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </DefaultLayout>
  );
};
export default AddCategory;
