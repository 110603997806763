import React, { useEffect, useState,useRef } from "react";
import Table from "../../components/Table";
import DefaultLayout from "../../layouts/DefaultLayout";
import { getData,putData} from "../../services/HttpServices";
import { setPageTitle,setBreadcrumbs  } from '../../store/resourceSlice';
import { useDispatch } from 'react-redux';
import { AlertError, AlertSuccess } from "../../components/Alerts";
const UserList = () => {
    const searchRef = useRef("");
    const dispatch = useDispatch();


    const [userData,setUserData] = useState([])
    const [userCounts,setUserCounts] = useState(0)
    const [pageNo,setPageNo] = useState(1)
    const [isNext,setIsNext] = useState(false)
    const [limit,setLimit] = useState(10)
    const [roles, setRoles] = useState([]);
    const [defaultRole, setDefaultRole] = useState(null);
    const [selectedRole, setSelectedRole] = useState(0);
    const [search, setSearchData] = useState("");
   let tablColumns = {
    id:'ID',
    name:'Name',
    email:'Email',
    phone:'Phone',
    role:'Role'
   }
    const getUseData = (page, query='',role='') =>{
        getData('/users/list?page='+page+'&query='+query+'&role='+role).then((response)=>{
            if(response.status === 200){
                let responseData = response.data
                setUserData(responseData.data.users)
                setUserCounts(responseData.data.counts)
                setPageNo(responseData.data.page_no)
                setIsNext(responseData.data.is_next)
                setLimit(responseData.data.limit)
            }
        })
    }
    const paginationUpdate = (pageno) => {
        setPageNo(pageno)
        getUseData(pageno,search, selectedRole)
    }
    const filterResult = (query) =>{
        getUseData(1,query,selectedRole)
    }
    useEffect(()=>{
        getUseData(pageNo)
        dispatch(setPageTitle('Users'))
        dispatch(setBreadcrumbs([
            {
                label: 'Dashboard',
                link: '/dashboard',
                active: false, // true,false
                state: 'users',
            },{
                label: 'Users',
                link: '/users',
                active: true, // true,false
                state: '',
            }
        ]))
        getData('/roles/filter').then((response)=>{
            if(response.status === 200){
                let responseData = response.data
                if(responseData.success){
                    setRoles(responseData.data)
                }
            }
        })
    // eslint-disable-next-line
    },[dispatch])
    const filterdata = () => {
        getUseData(1,search, selectedRole)
   }
   const updateRole = (res) => {

   if(res) {
        setDefaultRole(res)
        setSelectedRole(res.value)
    } else{
        setDefaultRole(null)
        setSelectedRole(0)
    }
   }
   const updateSearch = (search) => {
    setSearchData(search)
   }
   const deleteRecord = (id) => {
    if(id === 1){
        AlertError('Super admin account cannot be deleted')
        return;
    }
    if (window.confirm("Are you sure wan't to delete. This user will no longer access to portal or app?"))
        {
            putData('/users/delete/'+id).then((response)=>{
                if(response.status === 200){
                    let resposnseData = response.data
                    if(resposnseData.success === false){
                        AlertError(resposnseData.message)
                        return;
                    }
                    AlertSuccess(resposnseData.message)
                    setPageNo(pageNo)
                    getUseData(pageNo)
                }
         })
        }
   }
   const clearFilter = () => {
    setSearchData("")
    updateRole(null)
    setDefaultRole(null)
    getUseData(1,"")
    searchRef.current.value = ''
}
    return(
        <DefaultLayout pageTitle='Users'>
        <section className="section total-info">
            <div className="row">
            <div className="col-xl-12 col-md-12">
                <div className="card">
                    <div className="card-body">
                        <Table data={{list:userData, counts:userCounts, pageno: pageNo,limit:limit, isNext:isNext,column: tablColumns , editlink: '/users/' }} updateFn = {paginationUpdate} searchFn={filterResult}   delFun ={deleteRecord} submitFilter ={filterdata} clearfilter={clearFilter}
                        filters={[         
                            {
                                label:"Find by name, email",
                                type:'search',
                                class:'form-control',
                                default:search,
                                ref:searchRef,
                                searchFilterFn:updateSearch
                            },
                            {
                                label:"Select Role",
                                type:"select",
                                class:'form-control',
                                name:"role",
                                options:roles,
                                default:defaultRole,
                                changeOptionsFn:updateRole,
                                isClearable: true
                            }
                        ]} submenu={[
                                        {
                                            label:"Add User",
                                            link:'/users/add',
                                            buttonClass:'primary float-end'

                                        }
                                    ]}/>
                        </div>
                        </div>
                        </div>
                        </div>
           </section>
        </DefaultLayout>
    )
}
export default UserList;