import React, { useEffect, useState } from "react"
import DefaultLayout from "../../layouts/DefaultLayout"
import { useNavigate, useParams } from "react-router-dom"
import { getData, putData } from "../../services/HttpServices"
import { AlertError, AlertSuccess } from "../../components/Alerts"
import { useForm } from 'react-hook-form';
import Skeleton from "react-loading-skeleton"
import { setPageTitle,setBreadcrumbs } from '../../store/resourceSlice';
import { useDispatch } from 'react-redux';

const EditSupplier = ({ props }) => {
    const dispatch = useDispatch();
    /*Fetch user id */
    const navigate = useNavigate()
    const { id } = useParams()


    /*Inital state data*/
    const [supplierData, setSupplierData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [taxCodeData, setTaxCodeData] = useState([])
    //const [currency, setCurrency] = useState([])

    /*Update User Data*/
    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = data => {
        putData('/suppliers/update/' + id, data).then((response) => {
            let resposnseData = response.data
            if (resposnseData.success === false) {
                AlertError(resposnseData.message);
                return
            }
            AlertSuccess(resposnseData.message)
            navigate("/suppliers");
        }).catch(e => {
            AlertError(e);
        })
    }

    useEffect(() => {

        const  getTaxCodes = async () => {
            await getData("/taxcodes/filter").then((response) => {
                if (response.status === 200) {
                    let responseData = response.data;
                    if (responseData.success) {
                        setTaxCodeData(responseData.data);
                    }
                }
                getSupplierData()
            });
        };
        getTaxCodes();
        /*Get supplier data*/
        const getSupplierData = async () => {
            await getData('/suppliers/details/' + id).then((response) => {
                if (response.status === 200) {
                    let responseData = response.data
                    if (responseData.success) {
                        setSupplierData(responseData.data)
                    } else {
                        AlertError(responseData.message)
                        navigate('/suppliers')
                    }
                    setIsLoading(true)
                }
            })
        }

        dispatch(setPageTitle('Edit Supplier'))
        dispatch(setBreadcrumbs([
            {
                label: 'Dashboard',
                link: '/dashboard',
                active: false, // true,false
                state: 'supplier/edit',
            },{
                label: 'Suppliers',
                link: '/suppliers',
                active: false, // true,false
                state: 'supplier/edit',
            },{
                label: 'Edit Supplier',
                link: '/supplier/edit/'+id,
                active: true, // true,false
                state: 'supplier/edit',
            }
        ]))
    }, [id, navigate, dispatch])
    return (
        <DefaultLayout>
        <section className="section">
            <div className="row">
                <div className="card">
                    <div className="card-body">

                    <div className="container py-5 h-100">
<div className="row d-flex justify-content-center align-items-center h-100">

<div className="col-lg-12 col-xl-12">
                        {isLoading ?
                        <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row">
                            <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="code" className="col-sm-12 col-form-label">Code<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="code" defaultValue={supplierData.code}  {...register("code", { disabled: true, maxLength: 20})}/>
                                    </div>
                                   
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="name" className="col-sm-12 col-form-label">Name<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="name" defaultValue={supplierData.name}  {...register("name", {required: true , maxLength: 50})}/>
                                        {errors?.name && errors.name.type === "required" && <span className="error">Please enter name</span>}
                                        {errors?.name && errors.name.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                   
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="email" className="col-sm-12 col-form-label">Email</label>
                                    <div className="col-sm-12">
                                        <input type="email" className="form-control" id="email" defaultValue={supplierData.email} {...register("email", {required: false, pattern: {
                                            value: /\S+@\S+\.\S+/,
                                            message: "Entered value does not match email format",
                                        }})} />
                                        {errors?.email && errors.email.type === "required" && <span className="error">Please enter email</span>}
                                        {errors?.email && errors.email.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                    
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="phone" className="col-sm-12 col-form-label">Phone</label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="phone" defaultValue={supplierData.phone} {...register("phone", {required: false ,  minLength: 10,maxLength: 20})} />
                                        {errors?.phone && errors.phone.type === "required" && <span className="error">Please enter phone</span>}
                                        {errors?.phone && errors.phone.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                        {errors?.phone && errors.phone.type === "minLength" && <span className="error">Phone no should be min 10 digits</span> }
                                    </div>
                                    
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="fax" className="col-sm-12 col-form-label">Fax</label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="fax" defaultValue={supplierData.fax} {...register("fax", {required: false , maxLength: 20})} />
                                        {errors?.fax && errors.fax.type === "required" && <span className="error">Please enter fax</span>}
                                        {errors?.fax && errors.fax.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                    
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="contactname" className="col-sm-12 col-form-label">Contact Name<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="contactname" defaultValue={supplierData.contact_name} {...register("contactname", {required: true , maxLength: 20})} />
                                        {errors?.contactname && errors.contactname.type === "required" && <span className="error">Please enter contact name</span>}
                                        {errors?.contactname && errors.contactname.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                    
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="contactnumber" className="col-sm-12 col-form-label">Contact Number<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="contactnumber" defaultValue={supplierData.contact_number} {...register("contactnumber", {required: true , maxLength: 20})} />
                                        {errors?.contactnumber && errors.contactnumber.type === "required" && <span className="error">Please enter contact number</span>}
                                        {errors?.contactnumber && errors.contactnumber.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                    
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="address_1" className="col-sm-12 col-form-label">Address 1<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="address1" defaultValue={supplierData.address_1} {...register("address_1", {required: true , maxLength: 130})} />
                                        {errors?.address_1 && errors.address_1.type === "required" && <span className="error">Please enter address 1</span>}
                                        {errors?.address_1 && errors.address_1.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                    
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="address_1" className="col-sm-12 col-form-label">Address 2</label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="address_2" defaultValue={supplierData.address_2} {...register("address_2", {required: false , maxLength: 30})} />
                                        {errors?.address_2 && errors.address_2.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                    
                                </div>

                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="city" className="col-sm-12 col-form-label">City<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="city" defaultValue={supplierData.city} {...register("city", {required: true , maxLength: 20})} />
                                        {errors?.city && errors.city.type === "required" && <span className="error">Please enter city</span>}
                                        {errors?.city && errors.city.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="county" className="col-sm-12 col-form-label">County<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="county" defaultValue={supplierData.county} {...register("county", {required: true , maxLength: 20})} />
                                        {errors?.county && errors.county.type === "required" && <span className="error">Please enter county</span>}
                                        {errors?.county && errors.county.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="postcode" className="col-sm-12 col-form-label">Post Code</label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="postcode" defaultValue={supplierData.postcode} {...register("postcode", {required: false , maxLength: 90})} />
                                        {errors?.postcode && errors.postcode.type === "required" && <span className="error">Please enter post code</span>}
                                        {errors?.postcode && errors.postcode.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="gln_no" className="col-sm-12 col-form-label">GLN No</label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="gln_no" defaultValue={supplierData.gln_no} {...register("gln_no", {required: false , maxLength: 13})} />
                                        {errors?.gln_no && errors.gln_no.type === "required" && <span className="error">Please enter gln no</span>}
                                        {errors?.gln_no && errors.gln_no.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="taxcode" className="col-sm-12 col-form-label">Tax Code<span className="text-danger">*</span><small className="text-secondary mx-2"><i className="bi bi-info-circle-fill" title="The selected tax code will be applied to the purchase order, which will be created under this supplier."></i></small></label>
                                    <div className="col-sm-12">
                                    <select id="taxcode" className="form-control" defaultValue={ supplierData.tax_code_id } {...register("taxcode", {required: true})}>
                                    <option key={0} value={0}>Select a tax code</option>
                                            {taxCodeData.length && taxCodeData.map((taxCode, index) => (
                                            <option key={index} value={taxCode.value}>{taxCode.label}</option>
                                        ))}
                                         {errors?.taxcode && <span className="error">Please select a tax code</span>}
                                        </select>
                                    </div>
                                </div>
                                {/* <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="postcode" className="col-sm-12 col-form-label">Currency
                                    <span className="text-danger">*</span><small className="text-secondary mx-2"><i className="bi bi-info-circle-fill" title="The selected currency will be applied to the purchase order, which will be created under this supplier."></i></small> 
                                    </label>
                                    <div className="col-sm-12">
                                    
                                       <select id="currency" className="form-control" defaultValue={supplierData.currency} {...register("currency", {required: true })}>
                                       <option key={0} value={0}>Select a currency</option>
                                            {currency.length && currency.map((c, index) => (
                                            <option key={index} value={c.value}>{c.label}</option>
                                        ))}
                                         {errors?.currency && <span className="error">Please select a currency</span>}
                                        </select>
                                    </div> 
                                </div> */}
                                <div className="text-center">
                                    <button type="submit" className="btn btn-primary sub-btn">Submit</button>
                                </div>
                                    </div>
                            </form>
:<Skeleton style={{
    width: '100%',
    marginBottom:5,
    marginTop:5,
    height:20
  }} count={10} inline={true} />}
                        </div>
                        </div>
                        </div>
                           </div>
                        </div>
                        </div>
           </section>
        </DefaultLayout>
    )
}
export default EditSupplier;