import React from "react";
import Barcode from 'react-barcode';
export const DeliveryNote = React.forwardRef((props, ref) => {
  return (
         <div style={{maxWidth:"768px", width:"100%", margin:"0 auto" }} ref={ref}>
            <div style={{ fontFamily:"Source Sans Pro,Arial, sans-serif", fontSize:"15px", width:"100%", float:"left"}}>
            <div style={{ width:"48%", float:"left" }}>
                <img src="/images/logo.png" alt="" />
                <h5>Classic Mineral Water Pvt. Ltd.</h5>
                <address>
                    PO Box 1318, Wangara DC, WA 6947
                    <br/>
                    71 Furniss Road, Landsdale, WA 6065<br/>
                </address>
                Tel: <b>028 90 319360</b><br/>
                Web:<b>https://www.classicmineralwater.com/</b>
                <br/>
                Fax: <b>028 90 319360</b><br/>
                Email:<b>accounts@learningspaceuk.co.uk</b>
            </div>
            <div style={{ width:"50%", float:"left"}}>
                <h4 style={{ float:"right", margin:"0"}}>Sales invioce No. { props.orderData.order_number}</h4>
                <h5 style={{ float:"right", marginTop:"10px"}}>Original</h5>
                <table width="100%" cellPadding="0" cellSpacing="0" align="left" style={{ border:"1px solid #e8e8e8"}}>
                    <tbody>
                        <tr>
                            <th style={{ padding:"10px", background:"#e8e8e8"}} align="left">Gross Total</th>
                            <td align="right" style={{ padding:"10px"}}> <span dangerouslySetInnerHTML={{ __html: props.orderData.currency_symbol }}></span>{props.orderData.subTotal}</td>
                        </tr>
                        <tr>
                            <th style={{ padding:"10px", background:"#e8e8e8", borderTop:"1px solid #dddddd"}} align="left">Carriage</th>
                            <td align="right" style={{ padding:"10px", borderTop:"1px solid #e8e8e8"}}> <span dangerouslySetInnerHTML={{ __html: props.orderData.currency_symbol }}></span> 0.00</td>
                        </tr>
                        <tr>
                            <th style={{ padding:"10px", background:"#e8e8e8", borderTop:"1px solid #dddddd"}} align="left">Other</th>
                            <td align="right" style={{ padding:"10px", borderTop:"1px solid #e8e8e8"}}> <span dangerouslySetInnerHTML={{ __html: props.orderData.currency_symbol }}></span> 0.00</td>
                        </tr>
                        <tr>
                            <th style={{ padding:"10px", background:"#e8e8e8", borderTop:"1px solid #dddddd"}} align="left">Total</th>
                            <td style={{ padding:"10px", borderTop:"1px solid #e8e8e8"}} align="right"> <span dangerouslySetInnerHTML={{ __html: props.orderData.currency_symbol }}></span> {props.orderData.total}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div style={{fontFamily:"Source Sans Pro,Arial, sans-serif", fontSize:"15px", width:"100%", float:"left", marginTop:"50px"}}>
            <div style={{float:"left", width:"48%", margin:"0 auto" }}>
                <h5>Invoice to:</h5>
                { props.orderData.client_name}
            </div>
            <div style={{float:"left", width:"50%", margin:"0 auto" }}>
                <h5>Deliver to:</h5>
                { props.orderData.client_name}
            </div>
        </div>
        <div style={{fontFamily:"Source Sans Pro,Arial, sans-serif", fontSize:"15px", width:"100%", float:"left", marginTop:"30px"}}>
            <table width="100%" cellPadding="0" cellSpacing="0" align="left">
                <tbody>
                    <tr>
                        <th align="left" style={{borderBottom:"2px #333 solid", paddingTop:"10px", paddingBottom:"10px"}}>Item code</th>
                        <th align="left" style={{borderBottom:"2px #333 solid", paddingTop:"10px", paddingBottom:"10px"}}>Description</th>
                        <th align="right" style={{borderBottom:"2px #333 solid", paddingTop:"10px", paddingBottom:"10px"}}>Quantity</th>
                        <th align="right" style={{borderBottom:"2px #333 solid", paddingTop:"10px", paddingBottom:"10px"}}>Unit Price <span dangerouslySetInnerHTML={{ __html: props.orderData.currency_symbol }}></span></th>
                        <th align="right" style={{borderBottom:"2px #333 solid", paddingTop:"10px", paddingBottom:"10px"}}>Amount  <span dangerouslySetInnerHTML={{ __html: props.orderData.currency_symbol }}></span></th>
                        <th align="right" style={{borderBottom:"2px #333 solid", paddingTop:"10px", paddingBottom:"10px"}}>Dis %</th>
                        <th align="right" style={{borderBottom:"2px #333 solid", paddingTop:"10px", paddingBottom:"10px"}}>Line Total  <span dangerouslySetInnerHTML={{ __html: props.orderData.currency_symbol }}></span></th>
                    </tr>
                    { props.orderItems.length > 0 &&  props.orderItems.map((item, index) => (
                        <React.Fragment  key={index} >
                       <tr className={ item.item_status_id === 8 ? 'bg-danger text-white' :''}>
                         <td>{item.product_code}</td>
                           
                            <td align="left" style={{borderBottom:"1px #888888 solid", paddingTop:"10px", paddingBottom:"10px"}}>{item.product_description}</td>
                            <td align="left" style={{borderBottom:"1px #888888 solid", paddingTop:"10px", paddingBottom:"10px"}}>{item.quantity }</td>
                            <td align="left" style={{borderBottom:"1px #888888 solid", paddingTop:"10px", paddingBottom:"10px"}}>{(item.unit_price *1).toFixed(2) }</td>
                            <td align="left" style={{borderBottom:"1px #888888 solid", paddingTop:"10px", paddingBottom:"10px"}}>{(item.unit_price * item.quantity).toFixed(2)  }</td>
                            <td align="left" style={{borderBottom:"1px #888888 solid", paddingTop:"10px", paddingBottom:"10px"}}>0</td>
                            <td align="left" style={{borderBottom:"1px #888888 solid", paddingTop:"10px", paddingBottom:"10px"}}>{(item.unit_price * item.quantity).toFixed(2)  }</td>
                            
                        </tr> 
                            </React.Fragment>
                      ))}
                </tbody>
            </table>
        </div>
        <div style={{fontFamily:"Source Sans Pro,Arial, sans-serif", fontSize:"15px", width:"100%", float:"left", marginTop:"30px", pageBreakBefore:"always"}}>
             <div style={{ width:"100%", float:"left"}}>
                        <p>Delivery Instructions</p>
                        <p>{props.orderData.delivery_date}</p>
                        <p>{props.orderData.delivery_instructions}</p>
            </div>
            <div style={{ width:"45%", float:"left"}}>
                <table width="100%" cellPadding="0" cellSpacing="0" align="left"  style={{ border:"1px solid #e8e8e8"}}>
                    <tbody>
                        <tr>
                            <th style={{ padding:"10px", background:"#e8e8e8"}} align="left">Vat Code</th>
                            <th style={{ padding:"10px", background:"#e8e8e8"}} align="left">Vat rate</th>
                            <th style={{ padding:"10px", background:"#e8e8e8"}} align="left">Net Goods</th>
                            <th style={{ padding:"10px", background:"#e8e8e8"}} align="left">VAT</th>
                        </tr>
                        { props.orderData.vatcodes.length > 0 &&  props.orderData.vatcodes.map((vatcode, index) => (
                        <tr key={index}>
                            <td align="center" style={{ padding:"10px", borderTop:"1px solid #e8e8e8"}}>{vatcode.code}</td>
                            <td align="center" style={{ padding:"10px", borderTop:"1px solid #e8e8e8"}}>{vatcode.vat_rate}</td>
                            <td align="center" style={{ padding:"10px", borderTop:"1px solid #e8e8e8"}} >{(vatcode.itemtotal).toFixed(2) }</td>
                            <td align="center" style={{ padding:"10px", borderTop:"1px solid #e8e8e8"}}>{(vatcode.total).toFixed(2)
                            }</td>
                        </tr>
                        ))}
                        <tr>
                            <td style={{ padding:"10px", background:"#e8e8e8"}} align="center" colSpan="2">Your VAT ID</td>
                            <td align="center" colSpan="2" style={{ padding:"10px", borderTop:"1px solid #e8e8e8"}}>{props.orderData.vat_number}</td>
                        </tr>
                    </tbody>
                </table>
                <table width="100%" cellPadding="0" cellSpacing="0" align="left" style={{ marginTop:"25px", border:"1px solid #e8e8e8"}} >
                    <tbody>
                        <tr>
                            <td align="left" style={{ padding:"10px"}}>BIC:</td>
                            <td align="left" style={{ padding:"10px"}}></td>
                        </tr>
                        <tr>
                            <td align="left" style={{ padding:"10px"}}>IBAN:</td>
                            <td align="left" style={{ padding:"10px"}}></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div style={{ width:"35%", float:"right"}}>
                <table width="100%" cellPadding="0" cellSpacing="0" align="left" style={{ border:"1px solid #e8e8e8"}}>
                    <tbody>
                        <tr>
                            <th style={{ padding:"10px", borderTop:"1px solid #e8e8e8", borderRight:"1px solid #e8e8e8"}} align="left">Net Goods Less Discount</th>
                            <td align="left" style={{ padding:"10px", borderTop:"1px solid #e8e8e8"}} dangerouslySetInnerHTML={{ __html: props.orderData.currency_symbol }}></td>
                            <td align="left" style={{ padding:"10px", borderTop:"1px solid #e8e8e8"}}>{(props.orderData.subTotal.toFixed(2))}</td>
                        </tr>
                        <tr>
                            <th style={{ padding:"10px", borderTop:"1px solid #e8e8e8", borderRight:"1px solid #e8e8e8"}} align="left">Net Delivey Charges</th>
                            <td align="left" style={{ padding:"10px", borderTop:"1px solid #e8e8e8"}} dangerouslySetInnerHTML={{ __html: props.orderData.currency_symbol }}></td>
                            <td align="left" style={{ padding:"10px", borderTop:"1px solid #e8e8e8"}}>0.00</td>
                        </tr>
                        <tr>
                            <th style={{ padding:"10px", borderTop:"1px solid #e8e8e8", borderRight:"1px solid #e8e8e8"}} align="left">Net Goods & Delivery</th>
                            <td align="left" style={{ padding:"10px", borderTop:"1px solid #e8e8e8"}} dangerouslySetInnerHTML={{ __html: props.orderData.currency_symbol }}></td>
                            <td align="left" style={{ padding:"10px", borderTop:"1px solid #e8e8e8"}}>0.00</td>
                        </tr>
                        <tr>
                            <th style={{ padding:"10px", borderTop:"1px solid #e8e8e8", borderRight:"1px solid #e8e8e8"}} align="left">VAT</th>
                            <td align="left" style={{ padding:"10px", borderTop:"1px solid #e8e8e8"}} dangerouslySetInnerHTML={{ __html: props.orderData.currency_symbol }}></td>
                            <td align="left" style={{ padding:"10px", borderTop:"1px solid #e8e8e8"}}><b>{(props.orderData.vatTotal.toFixed(2))}</b></td>
                        </tr>
                        <tr>
                            <th style={{ padding:"10px", borderTop:"1px solid #e8e8e8", borderRight:"1px solid #e8e8e8"}} align="left">Total</th>
                            <td align="left" style={{ padding:"10px", borderTop:"1px solid #e8e8e8"}} dangerouslySetInnerHTML={{ __html: props.orderData.currency_symbol }}></td>
                            <td align="left" style={{ padding:"10px", borderTop:"1px solid #e8e8e8"}}><b>{(props.orderData.total).toFixed(2)}</b></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <p>GOODS & SERVICES REMAINS STRICTLY THE PROPERTY OF THE VENDOR UNTIL PAID FOR IN FULL</p>
        <div style={{fontFamily:"Source Sans Pro,Arial, sans-serif", fontSize:"15px", width:"100%", float:"left", marginTop:"30px"}}>
            <table width="100%" cellPadding="0" cellSpacing="0" align="left">
                <tbody>
                    <tr>
                        <th align="left" style={{borderBottom:"2px #333 solid", paddingTop:"10px", paddingBottom:"10px"}}>Company Reg. No.</th>
                        <th align="left" style={{borderBottom:"2px #333 solid", paddingTop:"10px", paddingBottom:"10px"}}>{props.orderData.client_code}</th>
                        <th align="right" style={{borderBottom:"2px #333 solid", paddingTop:"10px", paddingBottom:"10px"}}>Register Address</th>
                        <th align="right" style={{borderBottom:"2px #333 solid", paddingTop:"10px", paddingBottom:"10px"}}>-</th>
                        <th align="right" style={{borderBottom:"2px #333 solid", paddingTop:"10px", paddingBottom:"10px"}}></th>
                        <th align="right" style={{borderBottom:"2px #333 solid", paddingTop:"10px", paddingBottom:"10px"}}>VAT Reg.</th>
                        <th align="right" style={{borderBottom:"2px #333 solid", paddingTop:"10px", paddingBottom:"10px"}}>{props.orderData.vat_number}</th>
                    </tr>
                    <tr>
                        <td align="left" style={{borderBottom:"1px #888888 solid", paddingTop:"10px", paddingBottom:"10px"}}><b>Delivery Method</b></td>
                        <td align="right" style={{borderBottom:"1px #888888 solid", paddingTop:"10px", paddingBottom:"10px"}}></td>
                        <td align="right" style={{borderBottom:"1px #888888 solid", paddingTop:"10px", paddingBottom:"10px"}}></td>
                        <td align="right" style={{borderBottom:"1px #888888 solid", paddingTop:"10px", paddingBottom:"10px"}}><b>Delivery Agent</b></td>
                        <td align="right" style={{borderBottom:"1px #888888 solid", paddingTop:"10px", paddingBottom:"10px"}}></td>
                        <td align="right" style={{borderBottom:"1px #888888 solid", paddingTop:"10px", paddingBottom:"10px"}}><b>Delivery Service</b></td>
                        <td align="right" style={{borderBottom:"1px #888888 solid", paddingTop:"10px", paddingBottom:"10px"}}></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div style={{fontFamily:"Source Sans Pro,Arial, sans-serif", fontSize:"15px", width:"100%", float:"left", marginTop:"30px"}}>
            <table width="100%" cellPadding="0" cellSpacing="0" align="left">
                <tbody>
                    <tr>
                        <td align="left" style={{borderBottom:"1px #888888 solid", paddingTop:"10px", paddingBottom:"10px"}}> <Barcode value={props.orderData.order_number} height={40} /> </td>
                        <td align="right" style={{borderBottom:"1px #888888 solid", paddingTop:"10px", paddingBottom:"10px"}}><img src="/images/powered.png" alt="" /></td>
                    </tr>
                </tbody>
            </table>
        </div>
        </div>
    )
  })
