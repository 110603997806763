import React from "react";
import { Link } from "react-router-dom";
const AuthLayout = ({ children }) =>{
    return(
        <>
         <div className="container-fluid">
        <div className="row login-page justify-content-center align-items-center">
            <div className="col-lg-3 col-md-5 d-flex flex-column align-items-center justify-content-center">
                <div className="d-flex justify-content-center py-4">
                    <Link href="/" className="logo d-flex align-items-center w-auto">
                        <img src="/images/logo.png" alt="" />
                    </Link>
                </div>
                <div className="card mb-3">
                {children}
            </div>
            <div className="credits">
                    Designed by <a href="https://rkmsoftware.com">RKM Software</a>
                </div>
        </div>
        </div>
        </div>
        </>
    )
}
export default AuthLayout;