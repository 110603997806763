import React, { useEffect, useState } from "react"
import DefaultLayout from "../../../layouts/DefaultLayout"
import { useNavigate, useParams } from "react-router-dom"
import { getData, putData } from "../../../services/HttpServices"
import { AlertError, AlertSuccess } from "../../../components/Alerts"
import { useForm } from 'react-hook-form';
import Skeleton from "react-loading-skeleton"
import { setPageTitle,setBreadcrumbs } from '../../../store/resourceSlice';
import { useDispatch } from 'react-redux';
const EditCategory = () => {

    const dispatch = useDispatch();
    /*Fetch category id */
    const navigate = useNavigate()
    const { id } = useParams()
    /*Inital state data*/
    const [isLoading, setIsLoading] = useState(false)
    const [categoryData, setCategoryData] = useState([])
    /*Update category Data*/
    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = data => {
        putData('/category/update/' + id, data).then((response) => {
            let resposnseData = response.data
            if (resposnseData.success === false) {
                AlertError(resposnseData.message);
                return
            }
            AlertSuccess(resposnseData.message)
            navigate("/category");

        }).catch(e => {
            AlertError(e);
        })
    }
  

    useEffect(() => {
        /*Get category data*/
            getData('/category/details/' + id).then((response) => {
                if (response.status === 200) {
                    let responseData = response.data
                    if (responseData.success) {
                        setCategoryData(responseData.data)
                        setIsLoading(true)
                    } else {
                        AlertError(responseData.message)
                        navigate('/category')
                    }
                }
            })
        
        dispatch(setPageTitle('Edit Category'));
        dispatch(setBreadcrumbs([
            {
                label: 'Dashboard',
                link: '/dashboard',
                active: false, // true,false
                state: '/category/edit',
            },{
                label: 'Categories',
                link: '/category',
                active: false, // true,false
                state: '/category/edit',
            },{
              label: 'Edit Category',
              link: '/category/'+id,
              active: true, // true,false
              state: '/category/edit',
          }
          ]))
    }, [id, navigate, dispatch])
   
    return (
        <DefaultLayout>
        <section className="section">
            <div className="row">
                <div className="card">
                    <div className="card-body">


<div className="container py-5 h-100">
<div className="row d-flex justify-content-center align-items-center h-100">

<div className="col-lg-12 col-xl-12">


                        {isLoading ?
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                            <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="title" className="col-sm-12 col-form-label">Title<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="title" defaultValue={categoryData && categoryData.title} {...register("title", {required: true , maxLength: 50})} />
                                        {errors?.title && errors?.title.type === "required" && <span className="error">Please enter ean no</span>}
                                        {errors?.title && errors?.title.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                   
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="status" className="col-sm-12 col-form-label">Status <span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <select id="status" className="form-control" defaultValue={categoryData && categoryData.status ? categoryData.status:1 } {...register("status", {required: true})}>
                                            <option value={1}>Active</option>
                                            <option value={2}>In-Active</option>
                                        </select>
                                        {errors?.status && <span className="error">Please select a status</span>}
                                    </div>
                                   
                                </div>
                                <div className="text-center">
                                    <button type="submit" className="btn btn-primary sub-btn">Submit</button>
                                </div>
                                </div>
                            </form>
:<Skeleton style={{
    width: '100%',
    marginBottom:5,
    marginTop:5,
    height:20
  }} count={10} inline={true} />}
                        </div>
                        </div>
                        </div>
                               </div>
                        </div>
                        </div>
           </section>
        </DefaultLayout>
    )
}
export default EditCategory;