import React, { useEffect, useState,useRef } from "react";
import Table from "../../components/Table";
import DefaultLayout from "../../layouts/DefaultLayout";
import { getData } from "../../services/HttpServices";
import { setPageTitle,setBreadcrumbs } from '../../store/resourceSlice';
import { useDispatch } from 'react-redux';
const TaxCodeList = () => {
    const searchRef = useRef("");
    const dispatch = useDispatch();

    const [taxCodeData,setTaxCodeData] = useState([])
    const [taxCodeCounts,setTaxCountCounts] = useState(0)
    const [pageNo,setPageNo] = useState(1)
    const [isNext,setIsNext] = useState(false)
    const [limit,setLimit] = useState(10)
    const [search,setSearchData] = useState("")
   let tablColumns = {
    id:'ID',
    code:'Code',
    vat_rate:'VAT Rate'
   }
    const getTaxCodeData = (page, query='') =>{
        getData('/taxcodes/list?page='+page+'&query='+query).then((response)=>{
            if(response.status === 200){
                let responseData = response.data
                setTaxCodeData(responseData.data.taxcodes)
                setTaxCountCounts(responseData.data.counts)
                setPageNo(responseData.data.page_no)
                setIsNext(responseData.data.is_next)
                setLimit(responseData.data.limit)
            }
        })
    }
    const paginationUpdate = (pageno) => {
        setPageNo(pageno)
        getTaxCodeData(pageno,search)
    }

    const filterResult = () =>{
        getTaxCodeData(1,search)
    }
    const updateSearch = (data) => {
        setSearchData(data)
    }
    
    useEffect(()=>{
        getTaxCodeData(pageNo)
        dispatch(setPageTitle('Supplier Tax Codes'))
        dispatch(setBreadcrumbs([
            {
                label: 'Dashboard',
                link: '/dashboard',
                active: false, // true,false
                state: 'taxcodes',
            },{
                label: 'Supplier Tax Codes',
                link: '/taxcodes',
                active: true, // true,false
                state: 'taxcodes',
            }
        ]))
    // eslint-disable-next-line
    },[dispatch])
    const clearFilter = () => {
        setSearchData("")
        getTaxCodeData(1)
        searchRef.current.value = ''
    }
    return(
        <DefaultLayout pageTitle='Tax Codes'>
        <section className="section">
            <div className="row">
                <div className="card">
                    <div className="card-body mt-5">
                        <Table data={{list:taxCodeData, counts:taxCodeCounts, pageno: pageNo,limit:limit, isNext:isNext,column: tablColumns , editlink: '/taxcodes/' }} updateFn = {paginationUpdate} submitFilter={filterResult}  clearfilter={clearFilter}
                        filters={[         
                            {
                                label:"Find by code, vat rate",
                                type:'search',
                                class:'form-control',
                                default:search,
                                ref:searchRef,
                                searchFilterFn:updateSearch
                            }
                        ]}
                        submenu={[
                                        {
                                            label:"Add Tax Code",
                                            link:'/taxcodes/add',
                                            buttonClass:'primary float-end'

                                        }
                                    ]}/>
                        </div>
                        </div>
                        </div>
           </section>
        </DefaultLayout>
    )
}
export default TaxCodeList;