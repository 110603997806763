import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Col, Container, Row } from 'react-bootstrap';
import Select from "react-select";
import { PrimaryButton } from "../../components/Button";
const emptyObj = { product:null,drs: '',fileno: '',Ref: '',pallets: 0,bottles: 0,litresperpallets: 0,syrup: '',caps: '',actual_bottle:'',actual_syrup:'',barcode_pallet_sheet_colour:'',additonal_pallet_sheets:0}
function CreateFormPopup({ ...props }) {
      
      const [show, setShow] = useState();
      
      const [productRows,setProductRows] = useState();
      const handleClose = () => {
          props.hideFn();
          setProductRows(emptyObj)
      };
      const updateRow= (value, type)=>{
            let updateProductRows = {...productRows};

            updateProductRows[type] = value;
           
            if(type === 'pallets'){
              let perpalletebottles = parseInt(productRows.product ? productRows.product.palletperquantity:1)
              updateProductRows.bottles = parseInt(value*perpalletebottles);
            }
            if(type === 'product' && value){
              let perpalletebottles = parseInt(value.palletperquantity)
              updateProductRows.bottles = parseInt(productRows.pallets*perpalletebottles);
            }else if (type === 'product' && !value){
              updateProductRows['bottles']=0;
              updateProductRows['pallets']=0;
            }
            setProductRows(updateProductRows);
      }
      const submitForm = () => {
        props.addProduct(productRows)
        handleClose() 
      }
      useEffect(()=>{
        if(props.productRow){
            setProductRows(props.productRow)
          }else{
            setProductRows(emptyObj)
          }
      },[props.productRow])
      useEffect(() => {
          setShow(props.show);
            /*Get Products Data*/
      }, [props]);
     
      return ( <>
          <Modal
          show={show}
          onHide={handleClose}
          dialogClassName="modal-lg"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton className={'card-col'}>
            <Container fluid>
            <Row> 
              <Col xs={12} md={12}>
              <Modal.Title>Add Production Product</Modal.Title>
              </Col>
            </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
                    <React.Fragment>
                    <div className="row g-3 mb-3">
                    <div className="col-6 col-md-6">
                      <label className="col-form-label">Product<span className="text-danger">*</span></label>
                      <Select
                        options={props.productData}
                        defaultValue={productRows?.product}
                        className="col-12"
                        isSearchable={true}
                        isClearable={true}
                        onChange={(res)=> 
                            {
                                updateRow(res,'product')
                        }}
                      />
                    </div>
                    <div className="col-6 col-md-6">
                      <label className="col-form-label">DRS</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={productRows?.drs}
                        onInput={(res) => {
                            updateRow(res.target.value,'drs')
                        }}
                      />
                    </div>
                    </div>
                    <div className="row g-3 mb-3">
                    <div className="col-6 col-md-6">
                      <label className="col-form-label">Spec File Page no</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={productRows?.fileno}
                        onInput={(res) => {
                            updateRow(res.target.value,'fileno')
                        }}
                      />
                    </div>
                    <div className="col-6 col-md-6">
                      <label className="col-form-label">Spec Ref</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={productRows?.Ref}
                        onInput={(res) => {
                            updateRow(res.target.value,'Ref')
                        }}
                      />
                    </div>
                    </div>
                    <div className="row g-3 mb-3">
                    <div className="col-6 col-md-6">
                      <label className="col-form-label">Pallets</label>
                      <input
                        type="number"
                        min={1}
                        className="form-control"
                        value={productRows?.pallets}
                        onInput={(res) => {
                           updateRow(res.target.value,'pallets')
                        }}
                      />
                    </div>
                    <div className="col-6 col-md-6">
                      <label className="col-form-label">Bottles</label>
                      <input
                        type="number"
                        readOnly={true}
                        min={1}
                        className="form-control"
                        value={productRows?.bottles}
                      />
                    </div>
                    </div>
                    <div className="row g-3 mb-3">
                    <div className="col-6 col-md-6">
                      <label className="col-form-label">Litre Per Pallets</label>
                      <input
                        type="number"
                        min={1}
                        className="form-control"
                        defaultValue={productRows?.litresperpalltets}
                        onInput={(res) => {
                            updateRow(res.target.value,'litresperpallets')
                        }}
                      />
                    </div>
                    <div className="col-6 col-md-6">
                      <label className="col-form-label">Syrup</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={productRows?.syrup}
                        onInput={(res) => {
                            updateRow(res.target.value,'syrup')
                        }}
                      />
                    </div>
                    </div>
                    <div className="row g-3 mb-3">
                    <div className="col-6 col-md-6">
                      <label className="col-form-label">Caps</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={productRows?.caps}
                        onInput={(res) => {
                            updateRow(res.target.value,'caps')
                        }}
                      />
                    </div>
                    <div className="col-6 col-md-6">
                      <label className="col-form-label">Actual Bottle</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={productRows?.actual_bottle}
                        onInput={(res) => {
                            updateRow(res.target.value,'actual_bottle')
                        }}
                      />
                    </div>
                    </div>
                    <div className="row g-3 mb-3">
                    <div className="col-6 col-md-6">
                      <label className="col-form-label">Actual Syrup</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={productRows?.actual_syrup}
                        onInput={(res) => {
                            updateRow(res.target.value,'actual_syrup')
                        }}
                      />
                    </div>
                    <div className="col-6 col-md-6">
                      <label className="col-form-label">Barcode & Pallet Sheet Colour</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={productRows?.barcode_pallet_sheet_colour}
                        onInput={(res) => {
                            updateRow(res.target.value,'barcode_pallet_sheet_colour')
                        }}
                      />
                    </div>
                    </div>
                    <div className="row g-3 mb-3">
                    <div className="col-6 col-md-6">
                      <label className="col-form-label">ADDITIONAL PALLET SHEETS- 4 PER PALLET</label>
                      <input
                        type="number"
                        className="form-control"
                        defaultValue={productRows?.additonal_pallet_sheets}
                        onInput={(res) => {
                            updateRow(res.target.value,'additonal_pallet_sheets')
                        }}
                      />
                    </div>
                    </div>
                    <div className="row g-3 mb-3">
                    <div className="col-6 col-md-6">
                      <PrimaryButton buttonText="Submit" title="Submit" buttonClass="primary" clickfun={submitForm} iclass="patch-plus-fill" />
                    </div>
                    </div>
                  </React.Fragment>
          </Modal.Body>
        </Modal> </>
      );
  }
  
  export default CreateFormPopup;