import React from "react"
import DatePicker from "react-datepicker";
const DateRange = (props) => {
    return(
        <DatePicker
        className="form-control"
        selected={props.default}
        onChange={props.onDateRangeChange}
        startDate={props.startDate}
        endDate={props.endDate}
        dateFormat="dd/MM/yyyy"
        placeholderText={props.placeholderText ? props.placeholderText: "Click to select a date"}
        monthsShown={props.monthsShown ? props.monthsShown : 2}
        selectsRange
        isClearable
/>
)
}
export default DateRange;