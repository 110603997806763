import React from "react";
import DefaultLayout from "../../../layouts/DefaultLayout";
export const Invoice = React.forwardRef((props, ref) => {
  return (
    <DefaultLayout>
      <section className="section p-3">
        <div className="row">
          <div className="card-body mt-3 ">
            <div className="card">
              <div className="card-body">
                <div className="container-fluid">
                  <div className="row mx-2">
                    <div className="col-12" ref={ref}>
                    <div className="order-detail-col mb-0">
          {  props.orderData &&   
            <div className="grid invoice mt-3 mb-3" >
              <div className="grid-body">
                <div className="invoice-title">
                  <div className="row">
                    <div className="col-md-6 col-6">
                    <h4>Order:#{ props.orderData.order_number}</h4>
                    </div>
                  </div>
                </div>
                <hr/>
                <div className="row">
              <div className="col-md-4 col-12 mb-2">
              
                <div className="billed-col">
                <h6 className="fw-bold">Customer:</h6>
                          <address>
                         
                            <b>Account Code: </b>{ props.orderData.client_code} <br/>
                            { props.orderData.client_name} <br/>
                          </address>
                          </div>
                        </div>
                             
                  <div className="col-md-4 col-12 mb-2">
                 
                   <div className="billed-col">
                   <h6 className="fw-bold">Deliver To:</h6>
                    <address>
               
                      <b> { props.orderData.contact_name} </b><br/>
                      { props.orderData.address_1},{ props.orderData.address_2}<br/>
                      { props.orderData.county} ,  { props.orderData.country}<br/>
                      { props.orderData.postcode}<br/>
                       <i className="bi bi-phone-vibrate"></i> { props.orderData.contact_number?  props.orderData.contact_number : 'N/A'}<br/>
                       GLN Code:  { props.orderData.gln_code}<br/>
                    </address>
                  </div>  
                  </div>
               <div className="col-md-4 col-12 mb-2">
              
                      <div className="billed-col">
                      <h6 className="fw-bold">Order Information:</h6>
                    <address>
                      <span>Delivery Date: </span>{ props.orderData.delivery_date}<br/>
                     
                    </address>
                  </div>
                </div>
                  </div>
                <div className="row mt-3">
                  <div className="col-md-12 table-responsive">
                    <h5>ORDER SUMMARY</h5>
                    <table className="table table-striped">
                      <thead className="order-smr">
                        <tr>
                        <th scope="col"><strong>#</strong></th>
                        <th scope="col"><strong>Code</strong></th>
                        <th scope="col"><strong>Partner Code</strong></th>
                        <th scope="col"><strong>Description</strong></th>
                        <th scope="col"><strong>Barcode</strong></th>
                        <th scope="col"><strong>Quantity</strong></th>
                        <th scope="col"><strong>Pack</strong></th>
                      </tr>
                      </thead>
                      <tbody  className="order-sum">
                       { props.orderItems.length > 0 &&  props.orderItems.map((item, index) => (
                        <React.Fragment  key={index} >
                       <tr className={ item.item_status_id === 8 ? 'bg-danger text-white' :''}>
                            <th scope="row">{index + 1 }</th>
                            <td>{item.product_code}</td>
                            <td>{item.partner_code}</td>
                            <td>{item.product_description}
                            </td>
                            <td>{item.barcode}</td>
                            <td>{item.quantity }</td>
                            <td dangerouslySetInnerHTML={{ __html: item.pack }}></td>
                            
                        </tr> 
                            </React.Fragment>
                      ))}
                      </tbody>
                    </table>
                  </div>            
                </div>
              </div>
            </div>   
       
         
  }
  </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </DefaultLayout>
  );
});
