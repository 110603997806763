import React from "react";
import DefaultLayout from "../../../layouts/DefaultLayout";
export const Invoice = React.forwardRef((props, ref) => {
  return (
    <DefaultLayout>
      <section className="section" ref={ref}>
        <div className="row">
          <div className="card">
                            <div className="card-body">
                            { props.orderData &&   
                                <div className="container mb-5 mt-3">
                                    <div className="row d-flex align-items-baseline">
                                        <div className="col-xl-12">
                                            <h5>{props.orderData.delivery_name? props.orderData.delivery_name : 'N/A'}</h5>
                                        </div>
                                       
                                    </div>
                                    <hr/>
                                    <div className="container">
                                        <div className="col-md-12">
                                            <div className="text-center">
                                                <p className="pt-0">GLN No: {props.orderData.gln_no}</p>
                                            </div>
                                        </div>
                                        
                                         
                                        <div className="row">
                                            <div className="col-xl-12 text-end">
                                            <b>Invoice Address</b><br/>
                                              {props.orderData.delivery_name}<br/>
                                              {props.orderData.delivery_address_1},{props.orderData.delivery_address_2}<br/>
                                              {props.orderData.delivery_address_3} ,  {props.orderData.delivery_address_4}<br/>
                                              {props.orderData.delivery_post_code}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xl-6">
                                            <address>
                                              <b>{props.webInformation.owner_name}</b><br/>
                                              {props.webInformation.address}
                                            </address>
                                            </div>
                                            <div className="col-xl-6 text-end">
                                            <address>
                                              <b>Delivery Address</b><br/>
                                              {props.orderData.delivery_address_1},{props.orderData.delivery_address_2}<br/>
                                              {props.orderData.delivery_address_3} ,  {props.orderData.delivery_address_4}<br/>
                                              {props.orderData.delivery_post_code}
                                            </address>
                                            </div>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-xl-6">
                                                <p className="mb-0"><b>To :</b> {props.orderData.delivery_name? props.orderData.delivery_name : 'N/A'} </p>
                                                <p className="mb-0"><b>Phone :</b> {props.orderData.supplier_contact_number? props.orderData.supplier_contact_number : 'N/A'} </p>
                                                <p className="mb-0"><b>Fax : </b> {props.orderData.fax? props.orderData.fax : 'N/A'} </p>
                                                <p className="mb-0"><b>Sup. Ref :</b>  </p>
                                                <p className="mb-0"><b>Batch :</b>  </p>
                                            </div>
                                            <div className="col-xl-6 text-end">
                                                <p className="mb-0"><b>From :</b> {props.webInformation.owner_name} </p>
                                                <p className="mb-0"><b>Phone :</b> {props.webInformation.phone} </p>
                                                <p className="mb-0"><b>Fax :</b> {props.webInformation.fax} </p>
                                                <p className="mb-0"><b>Mail :</b> {props.webInformation.email} </p>
                                            </div>
                                        </div>
                                        <div className="row my-2 mx-1 justify-content-center">
                                            <h4 className="text-center mb-3 mt-3">Purchase Order</h4>
                                            <table className="table table-striped table-borderless">
                                                <thead className="text-white">
                                                    <tr>
                                                    <th scope="col"><strong>No</strong></th>
                                                    <th scope="col"><strong>Description</strong></th>
                                                    <th scope="col"><strong>GTIN No</strong></th>
                                                    <th scope="col"><strong>No Of Units</strong></th>
                                                    <th scope="col"><strong>Delivery</strong></th>
                                                    <th scope="col"><strong>Purchase Order No</strong></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {props.orderItems.length > 0 && props.orderItems.map((item, index) => (

                                                    <React.Fragment key={index}>
                                                    <tr key={index} className="mb-2">
                                                        <th scope="row"> {index + 1 } </th>
                                                        <td>{item.description} </td>
                                                        <td>{item.gtin_no} </td>
                                                        <td>{item.unit_quantity }</td>
                                                        <td>{item.delivery_date }</td>
                                                        <td>{props.orderData.id }</td>.
                                                        </tr>
                                                    
                                                    </React.Fragment>
                                                    ))} 
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="row">
                                            <div className="col-xl-12">
                                                <p>**All deliveries must be booked online through the DC Transport Planner, if the slot is unavailable for the requested delivery date please contact us directly. Opening hours for FRESH: Mon to Sun 10 pm to 6 am. All stock must be delivered on a good standard, stamped Euro pallets, fully shrink-wrapped. All paperwork MUST be handed to the Goods In window and MUST quote the purchase order number. All drivers must wear hi-vis and safety shoes at all times on site. For Fresh Delivery queries (Bread, M&amp;P and F&amp;V) please contact Storeavailabilitycontroller.nut@lidl.ie** Thank you</p>
                                            </div>
                                        </div>
                                        <hr/>
                                    </div>
                                </div>

                            }
                            </div>
                        </div>
                        </div>
                        </section>
    </DefaultLayout>
  );
});
